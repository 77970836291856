const actions = {
  // Regions ACTIONS
  LIST_REGIONS_BEGIN: 'LIST_REGIONS_BEGIN',
  LIST_REGIONS_SUCCESS: 'LIST_REGIONS_SUCCESS',
  LIST_REGIONS_ERR: 'LIST_REGIONS_ERR',

  // Cities ACTIONS
  LIST_CITIES_BEGIN: 'LIST_CITIES_BEGIN',
  LIST_CITIES_SUCCESS: 'LIST_CITIES_SUCCESS',
  LIST_CITIES_ERR: 'LIST_CITIES_ERR',

  // Districts ACTIONS
  LIST_DISTRICTS_BEGIN: 'LIST_DISTRICTS_BEGIN',
  LIST_DISTRICTS_SUCCESS: 'LIST_DISTRICTS_SUCCESS',
  LIST_DISTRICTS_ERR: 'LIST_DISTRICTS_ERR',

  listRegionsBegin: () => {
    return {
      type: actions.LIST_REGIONS_BEGIN,
    };
  },

  listRegionsSuccess: (data) => {
    return {
      type: actions.LIST_REGIONS_SUCCESS,
      data,
    };
  },

  listRegionsErr: (err) => {
    return {
      type: actions.LIST_REGIONS_ERR,
      err,
    };
  },

  listCitiesBegin: () => {
    return {
      type: actions.LIST_CITIES_BEGIN,
    };
  },

  listCitiesSuccess: (data) => {
    return {
      type: actions.LIST_CITIES_SUCCESS,
      data,
    };
  },

  listCitiesErr: (err) => {
    return {
      type: actions.LIST_CITIES_ERR,
      err,
    };
  },

  listDistrictsBegin: () => {
    return {
      type: actions.LIST_DISTRICTS_BEGIN,
    };
  },

  listDistrictsSuccess: (data) => {
    return {
      type: actions.LIST_DISTRICTS_SUCCESS,
      data,
    };
  },

  listDistrictsErr: (err) => {
    return {
      type: actions.LIST_DISTRICTS_ERR,
      err,
    };
  },
};

export default actions;
