import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const CreateCategoryForm = lazy(() => import('../../container/appCategories/CreateCategoryForm'));
const EditCategoryForm = lazy(() => import('../../container/appCategories/EditCategoryForm'));
const CategoriesList = lazy(() => import('../../container/appCategories'));

function IconsRoute() {
  return (
    <Routes>
      <Route path="/" element={<CategoriesList />} />
      <Route path="/add-category" element={<CreateCategoryForm />} />
      <Route path="/:id/edit" element={<EditCategoryForm />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default IconsRoute;
