import actions from './actions';

const {
  LIST_ORDERS_BEGIN,
  LIST_ORDERS_SUCCESS,
  LIST_ORDERS_ERR,
  ORDER_DETAILS_BEGIN,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_ERR,
  SALES_SUMMARY_REPORT_BEGIN,
  SALES_SUMMARY_REPORT_SUCCESS,
  SALES_SUMMARY_REPORT_ERR,
} = actions;

const initState = {
  loadingListOrders: false,
  errorListOrders: '',
  orders: [],
  loadingOrderDetails: false,
  errorOrderDetails: '',
  orderDetails: {},
  totalOrders: 0,
  totalOrdersPages: 0,
  salesSummaryReport: [],
  loadingSalesSummaryReport: false,
  errorSalesSummaryReport: '',
  totalCostPrice: 0,
  anwarAlasrTotalSoldPrice: 0,
  anwarAlasrTotalCostCommission: 0,
  totalAvg: 0,
  anwarAlasrTotalAvgCostCommission: 0,
  agentSoldPrice: 0,
  agentTotalCommission: 0,
};

const salesReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LIST_ORDERS_BEGIN:
      return {
        ...state,
        loadingListOrders: true,
        errorListOrders: null,
        totalOrders: 0,
        totalOrdersPages: 0,
      };
    case LIST_ORDERS_SUCCESS:
      return {
        ...state,
        orders: [...data.results],
        loadingListOrders: false,
        errorListOrders: null,
        totalOrders: data.total_count,
        totalOrdersPages: data.total_pages,
      };
    case LIST_ORDERS_ERR:
      return {
        ...state,
        errorListOrders: err,
        loadingListOrders: false,
        totalOrders: 0,
        totalOrdersPages: 0,
      };
    case ORDER_DETAILS_BEGIN:
      return {
        ...state,
        loadingOrderDetails: true,
        errorOrderDetails: null,
      };
    case ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: data,
        loadingOrderDetails: false,
        errorOrderDetails: null,
      };
    case ORDER_DETAILS_ERR:
      return {
        ...state,
        errorOrderDetails: err,
        loadingOrderDetails: false,
      };

    case SALES_SUMMARY_REPORT_BEGIN:
      return {
        ...state,
        salesSummaryReport: [],
        loadingSalesSummaryReport: true,
        errorSalesSummaryReport: null,
        totalCostPrice: 0,
        totalAvgCostPrice: 0,
        anwarAlasrTotalSoldPrice: 0,
        anwarAlasrTotalCostCommission: 0,
        anwarAlasrTotalAvgCostCommission: 0,
        totalQty: 0,
        agentSoldPrice: 0,
        agentTotalCommission: 0,
      };
    case SALES_SUMMARY_REPORT_SUCCESS:
      return {
        ...state,
        salesSummaryReport: [...data.results],
        loadingSalesSummaryReport: false,
        errorSalesSummaryReport: null,
        totalCostPrice: data.total_cost_price,
        anwarAlasrTotalSoldPrice: data.anwar_alasr_total_sold_price,
        anwarAlasrTotalCostCommission: data.anwar_alasr_total_cost_commission,
        anwarAlasrTotalAvgCostCommission: data.anwar_alasr_total_avg_cost_commission,
        totalAvgCostPrice: data.total_avg_cost_price,
        totalQty: data.total_qty,
        agentSoldPrice: data.agent_sold_price,
        agentTotalCommission: data.agent_total_commission,
      };
    case SALES_SUMMARY_REPORT_ERR:
      return {
        ...state,
        errorSalesSummaryReport: err,
        loadingSalesSummaryReport: false,
        totalAvgCostPrice: 0,
        totalCostPrice: 0,
        anwarAlasrTotalSoldPrice: 0,
        anwarAlasrTotalCostCommission: 0,
        anwarAlasrTotalAvgCostCommission: 0,
        totalQty: 0,
        agentSoldPrice: 0,
        agentTotalCommission: 0,
      };
    default:
      return state;
  }
};

export { salesReducer };
