const actions = {
  // TODO:  TO be deleted
  CREATE_USER_BEGIN: 'CREATE_USER_BEGIN',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_ERR: 'CREATE_USER_ERR',

  // DASHBOARD ADMIN ACTIONS
  CREATE_DASHBOARD_ADMIN_BEGIN: 'CREATE_DASHBOARD_ADMIN_BEGIN',
  CREATE_DASHBOARD_ADMIN_SUCCESS: 'CREATE_DASHBOARD_ADMIN_SUCCESS',
  CREATE_DASHBOARD_ADMIN_ERR: 'CREATE_DASHBOARD_ADMIN_ERR',

  LIST_DASHBOARD_ADMINS_BEGIN: 'LIST_DASHBOARD_ADMINS_BEGIN',
  LIST_DASHBOARD_ADMINS_SUCCESS: 'LIST_DASHBOARD_ADMINS_SUCCESS',
  LIST_DASHBOARD_ADMINS_ERR: 'LIST_DASHBOARD_ADMINS_ERR',

  UPDATE_DASHBOARD_ADMIN_BEGIN: 'UPDATE_DASHBOARD_ADMIN_BEGIN',
  UPDATE_DASHBOARD_ADMIN_SUCCESS: 'UPDATE_DASHBOARD_ADMIN_SUCCESS',
  UPDATE_DASHBOARD_ADMIN_ERR: 'UPDATE_DASHBOARD_ADMIN_ERR',

  // DASHBOARD ADMIN USER ACTIONS
  CREATE_DASHBOARD_USER_BEGIN: 'CREATE_DASHBOARD_USER_BEGIN',
  CREATE_DASHBOARD_USER_SUCCESS: 'CREATE_DASHBOARD_USER_SUCCESS',
  CREATE_DASHBOARD_USER_ERR: 'CREATE_DASHBOARD_USER_ERR',

  LIST_DASHBOARD_USERS_BEGIN: 'LIST_DASHBOARD_USERS_BEGIN',
  LIST_DASHBOARD_USERS_SUCCESS: 'LIST_DASHBOARD_USERS_SUCCESS',
  LIST_DASHBOARD_USERS_ERR: 'LIST_DASHBOARD_USERS_ERR',

  UPDATE_DASHBOARD_USER_BEGIN: 'UPDATE_DASHBOARD_USER_BEGIN',
  UPDATE_DASHBOARD_USER_SUCCESS: 'UPDATE_DASHBOARD_USER_SUCCESS',
  UPDATE_DASHBOARD_USER_ERR: 'UPDATE_DASHBOARD_USER_ERR',

  // AGENT ENTITY ACTIONS
  CREATE_AGENT_ENTITY_BEGIN: 'CREATE_AGENT_ENTITY_BEGIN',
  CREATE_AGENT_ENTITY_SUCCESS: 'CREATE_AGENT_ENTITY_SUCCESS',
  CREATE_AGENT_ENTITY_ERR: 'CREATE_AGENT_ENTITY_ERR',

  LIST_AGENT_ENTITIES_BEGIN: 'LIST_AGENT_ENTITIES_BEGIN',
  LIST_AGENT_ENTITIES_SUCCESS: 'LIST_AGENT_ENTITIES_SUCCESS',
  LIST_AGENT_ENTITIES_ERR: 'LIST_AGENT_ENTITIES_ERR',

  UPDATE_AGENT_ENTITY_BEGIN: 'UPDATE_AGENT_ENTITY_BEGIN',
  UPDATE_AGENT_ENTITY_SUCCESS: 'UPDATE_AGENT_ENTITY_SUCCESS',
  UPDATE_AGENT_ENTITY_ERR: 'UPDATE_AGENT_ENTITY_ERR',

  // AGENT USER ACTIONS
  CREATE_AGENT_USER_BEGIN: 'CREATE_AGENT_USER_BEGIN',
  CREATE_AGENT_USER_SUCCESS: 'CREATE_AGENT_USER_SUCCESS',
  CREATE_AGENT_USER_ERR: 'CREATE_AGENT_USER_ERR',

  LIST_AGENT_USERS_BEGIN: 'LIST_AGENT_USERS_BEGIN',
  LIST_AGENT_USERS_SUCCESS: 'LIST_AGENT_USERS_SUCCESS',
  LIST_AGENT_USERS_ERR: 'LIST_AGENT_USERS_ERR',

  UPDATE_AGENT_USER_BEGIN: 'UPDATE_AGENT_USER_BEGIN',
  UPDATE_AGENT_USER_SUCCESS: 'UPDATE_AGENT_USER_SUCCESS',
  UPDATE_AGENT_USER_ERR: 'UPDATE_AGENT_USER_ERR',

  // DIRECT DELEGATE ACTIONS
  CREATE_DIRECT_DELEGATE_BEGIN: 'CREATE_DIRECT_DELEGATE_BEGIN',
  CREATE_DIRECT_DELEGATE_SUCCESS: 'CREATE_DIRECT_DELEGATE_SUCCESS',
  CREATE_DIRECT_DELEGATE_ERR: 'CREATE_DIRECT_DELEGATE_ERR',

  LIST_DIRECT_DELEGATES_BEGIN: 'LIST_DIRECT_DELEGATES_BEGIN',
  LIST_DIRECT_DELEGATES_SUCCESS: 'LIST_DIRECT_DELEGATES_SUCCESS',
  LIST_DIRECT_DELEGATES_ERR: 'LIST_DIRECT_DELEGATES_ERR',

  UPDATE_DIRECT_DELEGATE_BEGIN: 'UPDATE_DIRECT_DELEGATE_BEGIN',
  UPDATE_DIRECT_DELEGATE_SUCCESS: 'UPDATE_DIRECT_DELEGATE_SUCCESS',
  UPDATE_DIRECT_DELEGATE_ERR: 'UPDATE_DIRECT_DELEGATE_ERR',

  // DIRECT MERCHANT ACTIONS
  CREATE_DIRECT_MERCHANT_BEGIN: 'CREATE_DIRECT_MERCHANT_BEGIN',
  CREATE_DIRECT_MERCHANT_SUCCESS: 'CREATE_DIRECT_MERCHANT_SUCCESS',
  CREATE_DIRECT_MERCHANT_ERR: 'CREATE_DIRECT_MERCHANT_ERR',

  LIST_DIRECT_MERCHANTS_BEGIN: 'LIST_DIRECT_MERCHANTS_BEGIN',
  LIST_DIRECT_MERCHANTS_SUCCESS: 'LIST_DIRECT_MERCHANTS_SUCCESS',
  LIST_DIRECT_MERCHANTS_ERR: 'LIST_DIRECT_MERCHANTS_ERR',

  UPDATE_DIRECT_MERCHANT_BEGIN: 'UPDATE_DIRECT_MERCHANT_BEGIN',
  UPDATE_DIRECT_MERCHANT_SUCCESS: 'UPDATE_DIRECT_MERCHANT_SUCCESS',
  UPDATE_DIRECT_MERCHANT_ERR: 'UPDATE_DIRECT_MERCHANT_ERR',

  // DIRECT MERCHANT USER ACTIONS
  CREATE_DIRECT_MERCHANT_USER_BEGIN: 'CREATE_DIRECT_MERCHANT_USER_BEGIN',
  CREATE_DIRECT_MERCHANT_USER_SUCCESS: 'CREATE_DIRECT_MERCHANT_USER_SUCCESS',
  CREATE_DIRECT_MERCHANT_USER_ERR: 'CREATE_DIRECT_MERCHANT_USER_ERR',

  LIST_DIRECT_MERCHANT_USERS_BEGIN: 'LIST_DIRECT_MERCHANT_USERS_BEGIN',
  LIST_DIRECT_MERCHANT_USERS_SUCCESS: 'LIST_DIRECT_MERCHANT_USERS_SUCCESS',
  LIST_DIRECT_MERCHANT_USERS_ERR: 'LIST_DIRECT_MERCHANT_USERS_ERR',

  UPDATE_DIRECT_MERCHANT_USER_BEGIN: 'UPDATE_DIRECT_MERCHANT_USER_BEGIN',
  UPDATE_DIRECT_MERCHANT_USER_SUCCESS: 'UPDATE_DIRECT_MERCHANT_USER_SUCCESS',
  UPDATE_DIRECT_MERCHANT_USER_ERR: 'UPDATE_DIRECT_MERCHANT_USER_ERR',

  // GENERIC USER (all users) ACTIONS, only DELETE
  DELETE_GENERIC_USER_BEGIN: 'DELETE_GENERIC_USER_BEGIN',
  DELETE_GENERIC_USER_SUCCESS: 'DELETE_GENERIC_USER_SUCCESS',
  DELETE_GENERIC_USER_ERR: 'DELETE_GENERIC_USER_ERR',

  BLOCK_GENERIC_USER_BEGIN: 'BLOCK_GENERIC_USER_BEGIN',
  BLOCK_GENERIC_USER_SUCCESS: 'BLOCK_GENERIC_USER_SUCCESS',
  BLOCK_GENERIC_USER_ERR: 'BLOCK_GENERIC_USER_ERR',

  UNBLOCK_GENERIC_USER_BEGIN: 'UNBLOCK_GENERIC_USER_BEGIN',
  UNBLOCK_GENERIC_USER_SUCCESS: 'UNBLOCK_GENERIC_USER_SUCCESS',
  UNBLOCK_GENERIC_USER_ERR: 'UNBLOCK_GENERIC_USER_ERR',

  GET_GENERIC_USER_DETAILS_BEGIN: 'GET_GENERIC_USER_DETAILS_BEGIN',
  GET_GENERIC_USER_DETAILS_SUCCESS: 'GET_GENERIC_USER_DETAILS_SUCCESS',
  GET_GENERIC_USER_DETAILS_ERR: 'GET_GENERIC_USER_DETAILS_ERR',

  // GENERIC ACCOUNT DETAILS

  // TODO: to be deleted
  createUserBegin: () => {
    return {
      type: actions.CREATE_USER_BEGIN,
    };
  },

  createUserSuccess: (data) => {
    return {
      type: actions.CREATE_USER_SUCCESS,
      data,
    };
  },

  createUserErr: (err) => {
    return {
      type: actions.CREATE_USER_ERR,
      err,
    };
  },

  createDashboardAdminBegin: () => {
    return {
      type: actions.CREATE_DASHBOARD_ADMIN_BEGIN,
    };
  },

  createDashboardAdminSuccess: (data) => {
    return {
      type: actions.CREATE_DASHBOARD_ADMIN_SUCCESS,
      data,
    };
  },

  createDashboardAdminErr: (err) => {
    return {
      type: actions.CREATE_DASHBOARD_ADMIN_ERR,
      err,
    };
  },

  listDashboardAdminsBegin: () => {
    return {
      type: actions.LIST_DASHBOARD_ADMINS_BEGIN,
    };
  },

  listDashboardAdminsSuccess: (data) => {
    return {
      type: actions.LIST_DASHBOARD_ADMINS_SUCCESS,
      data,
    };
  },

  listDashboardAdminsErr: (err) => {
    return {
      type: actions.LIST_DASHBOARD_ADMINS_ERR,
      err,
    };
  },

  createDashboardUserBegin: () => {
    return {
      type: actions.CREATE_DASHBOARD_USER_BEGIN,
    };
  },

  createDashboardUserSuccess: (data) => {
    return {
      type: actions.CREATE_DASHBOARD_USER_SUCCESS,
      data,
    };
  },

  createDashboardUserErr: (err) => {
    return {
      type: actions.CREATE_DASHBOARD_USER_ERR,
      err,
    };
  },

  createDirectMerchantBegin: () => {
    return {
      type: actions.CREATE_DIRECT_MERCHANT_BEGIN,
    };
  },

  createDirectMerchantSuccess: (data) => {
    return {
      type: actions.CREATE_DIRECT_MERCHANT_SUCCESS,
      data,
    };
  },

  createDirectMerchantErr: (err) => {
    return {
      type: actions.CREATE_DIRECT_MERCHANT_ERR,
      err,
    };
  },

  createDirectMerchantUserBegin: () => {
    return {
      type: actions.CREATE_DIRECT_MERCHANT_USER_BEGIN,
    };
  },

  createDirectMerchantUserSuccess: (data) => {
    return {
      type: actions.CREATE_DIRECT_MERCHANT_USER_SUCCESS,
      data,
    };
  },

  createDirectMerchantUserErr: (err) => {
    return {
      type: actions.CREATE_DIRECT_MERCHANT_USER_ERR,
      err,
    };
  },

  listDashboardUsersBegin: () => {
    return {
      type: actions.LIST_DASHBOARD_USERS_BEGIN,
    };
  },

  listDashboardUsersSuccess: (data) => {
    return {
      type: actions.LIST_DASHBOARD_USERS_SUCCESS,
      data,
    };
  },

  listDashboardUsersErr: (err) => {
    return {
      type: actions.LIST_DASHBOARD_USERS_ERR,
      err,
    };
  },

  createAgentEntityBegin: () => {
    return {
      type: actions.CREATE_AGENT_ENTITY_BEGIN,
    };
  },

  createAgentEntitySuccess: (data) => {
    return {
      type: actions.CREATE_AGENT_ENTITY_SUCCESS,
      data,
    };
  },

  createAgentEntityErr: (err) => {
    return {
      type: actions.CREATE_AGENT_ENTITY_ERR,
      err,
    };
  },

  listAgentEntitiesBegin: () => {
    return {
      type: actions.LIST_AGENT_ENTITIES_BEGIN,
    };
  },

  listAgentEntitiesSuccess: (data) => {
    return {
      type: actions.LIST_AGENT_ENTITIES_SUCCESS,
      data,
    };
  },

  listAgentEntitiesErr: (err) => {
    return {
      type: actions.LIST_AGENT_ENTITIES_ERR,
      err,
    };
  },

  createAgentUserBegin: () => {
    return {
      type: actions.CREATE_AGENT_USER_BEGIN,
    };
  },

  createAgentUserSuccess: (data) => {
    return {
      type: actions.CREATE_AGENT_USER_SUCCESS,
      data,
    };
  },

  createAgentUserErr: (err) => {
    return {
      type: actions.CREATE_AGENT_USER_ERR,
      err,
    };
  },

  listAgentUsersBegin: () => {
    return {
      type: actions.LIST_AGENT_USERS_BEGIN,
    };
  },

  listAgentUsersSuccess: (data) => {
    return {
      type: actions.LIST_AGENT_USERS_SUCCESS,
      data,
    };
  },

  listAgentUsersErr: (err) => {
    return {
      type: actions.LIST_AGENT_USERS_ERR,
      err,
    };
  },

  createDirectDelegateBegin: () => {
    return {
      type: actions.CREATE_DIRECT_DELEGATE_BEGIN,
    };
  },

  createDirectDelegateSuccess: (data) => {
    return {
      type: actions.CREATE_DIRECT_DELEGATE_SUCCESS,
      data,
    };
  },

  createDirectDelegateErr: (err) => {
    return {
      type: actions.CREATE_DIRECT_DELEGATE_ERR,
      err,
    };
  },

  listDirectDelegatesBegin: () => {
    return {
      type: actions.LIST_DIRECT_DELEGATES_BEGIN,
    };
  },

  listDirectDelegatesSuccess: (data) => {
    return {
      type: actions.LIST_DIRECT_DELEGATES_SUCCESS,
      data,
    };
  },

  listDirectDelegatesErr: (err) => {
    return {
      type: actions.LIST_DIRECT_DELEGATES_ERR,
      err,
    };
  },

  listDirectMerchantsBegin: () => {
    return {
      type: actions.LIST_DIRECT_MERCHANTS_BEGIN,
    };
  },

  listDirectMerchantsSuccess: (data) => {
    return {
      type: actions.LIST_DIRECT_MERCHANTS_SUCCESS,
      data,
    };
  },

  listDirectMerchantsErr: (err) => {
    return {
      type: actions.LIST_DIRECT_MERCHANTS_ERR,
      err,
    };
  },

  listDirectMerchantUsersBegin: () => {
    return {
      type: actions.LIST_DIRECT_MERCHANT_USERS_BEGIN,
    };
  },

  listDirectMerchantUsersSuccess: (data) => {
    return {
      type: actions.LIST_DIRECT_MERCHANT_USERS_SUCCESS,
      data,
    };
  },

  listDirectMerchantUsersErr: (err) => {
    return {
      type: actions.LIST_DIRECT_MERCHANT_USERS_ERR,
      err,
    };
  },

  updateDashboardAdminBegin: () => {
    return {
      type: actions.UPDATE_DASHBOARD_ADMIN_BEGIN,
    };
  },

  updateDashboardAdminSuccess: (data) => {
    return {
      type: actions.UPDATE_DASHBOARD_ADMIN_SUCCESS,
      data,
    };
  },

  updateDashboardAdminErr: (err) => {
    return {
      type: actions.UPDATE_DASHBOARD_ADMIN_ERR,
      err,
    };
  },

  updateDashboardUserBegin: () => {
    return {
      type: actions.UPDATE_DASHBOARD_USER_BEGIN,
    };
  },

  updateDashboardUserSuccess: (data) => {
    return {
      type: actions.UPDATE_DASHBOARD_USER_SUCCESS,
      data,
    };
  },

  updateDashboardUserErr: (err) => {
    return {
      type: actions.UPDATE_DASHBOARD_USER_ERR,
      err,
    };
  },

  updateAgentEntityBegin: () => {
    return {
      type: actions.UPDATE_AGENT_ENTITY_BEGIN,
    };
  },

  updateAgentEntitySuccess: (data) => {
    return {
      type: actions.UPDATE_AGENT_ENTITY_SUCCESS,
      data,
    };
  },

  updateAgentEntityErr: (err) => {
    return {
      type: actions.UPDATE_AGENT_ENTITY_ERR,
      err,
    };
  },

  updateAgentUserBegin: () => {
    return {
      type: actions.UPDATE_AGENT_USER_BEGIN,
    };
  },

  updateAgentUserSuccess: (data) => {
    return {
      type: actions.UPDATE_AGENT_USER_SUCCESS,
      data,
    };
  },

  updateAgentUserErr: (err) => {
    return {
      type: actions.UPDATE_AGENT_USER_ERR,
      err,
    };
  },

  updateDirectDelegateBegin: () => {
    return {
      type: actions.UPDATE_DIRECT_DELEGATE_BEGIN,
    };
  },

  updateDirectDelegateSuccess: (data) => {
    return {
      type: actions.UPDATE_DIRECT_DELEGATE_SUCCESS,
      data,
    };
  },

  updateDirectDelegateErr: (err) => {
    return {
      type: actions.UPDATE_DIRECT_DELEGATE_ERR,
      err,
    };
  },
  updateDirectMerchantBegin: () => {
    return {
      type: actions.UPDATE_DIRECT_MERCHANT_BEGIN,
    };
  },

  updateDirectMerchantSuccess: (data) => {
    return {
      type: actions.UPDATE_DIRECT_MERCHANT_SUCCESS,
      data,
    };
  },

  updateDirectMerchantErr: (err) => {
    return {
      type: actions.UPDATE_DIRECT_MERCHANT_ERR,
      err,
    };
  },
  updateDirectMerchantUserBegin: () => {
    return {
      type: actions.UPDATE_DIRECT_MERCHANT_USER_BEGIN,
    };
  },

  updateDirectMerchantUserSuccess: (data) => {
    return {
      type: actions.UPDATE_DIRECT_MERCHANT_USER_SUCCESS,
      data,
    };
  },

  updateDirectMerchantUserErr: (err) => {
    return {
      type: actions.UPDATE_DIRECT_MERCHANT_USER_ERR,
      err,
    };
  },

  deleteGenericUserBegin: () => {
    return {
      type: actions.DELETE_GENERIC_USER_BEGIN,
    };
  },

  deleteGenericUserSuccess: (data) => {
    return {
      type: actions.DELETE_GENERIC_USER_SUCCESS,
      data,
    };
  },

  deleteGenericUserErr: (err) => {
    return {
      type: actions.DELETE_GENERIC_USER_ERR,
      err,
    };
  },

  blockGenericUserBegin: () => {
    return {
      type: actions.BLOCK_GENERIC_USER_BEGIN,
    };
  },

  blockGenericUserSuccess: (data) => {
    return {
      type: actions.BLOCK_GENERIC_USER_SUCCESS,
      data,
    };
  },

  blockGenericUserErr: (err) => {
    return {
      type: actions.BLOCK_GENERIC_USER_ERR,
      err,
    };
  },

  unblockGenericUserBegin: () => {
    return {
      type: actions.UNBLOCK_GENERIC_USER_BEGIN,
    };
  },

  unblockGenericUserSuccess: (data) => {
    return {
      type: actions.UNBLOCK_GENERIC_USER_SUCCESS,
      data,
    };
  },

  unblockGenericUserErr: (err) => {
    return {
      type: actions.UNBLOCK_GENERIC_USER_ERR,
      err,
    };
  },

  getGenericUserDetailsBegin: () => {
    return {
      type: actions.GET_GENERIC_USER_DETAILS_BEGIN,
    };
  },

  getGenericUserDetailsSuccess: (data) => {
    return {
      type: actions.GET_GENERIC_USER_DETAILS_SUCCESS,
      data,
    };
  },

  getGenericUserDetailsErr: (err) => {
    return {
      type: actions.GET_GENERIC_USER_DETAILS_ERR,
      err,
    };
  },
};

export default actions;
