import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const AllProductsList = lazy(() => import('../../container/products/AllCardsListing'));

function IconsRoute() {
  return (
    <Routes>
      <Route path="/" element={<AllProductsList />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default IconsRoute;
