import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import { isUserAuthorized } from '../../common/Authorization';
import { UserTypes } from '../../common/UserTypes';

const AddDashboardAdmin = lazy(() => import('../../container/users/dashboard-admin/AddDashboardAdmin'));
const DashboardAdminList = lazy(() => import('../../container/users/dashboard-admin/DashboardAdminList'));
const DashboardAdminDetails = lazy(() =>
  import('../../container/users/dashboard-admin/components/DashboardAdminDetails'),
);

const AddDashboardAdminUser = lazy(() => import('../../container/users/dashboard-user/AddDashboardAdminUser'));
const DashboardUsersList = lazy(() => import('../../container/users/dashboard-user/DashboardUsersList'));
const DashboardUserDetails = lazy(() => import('../../container/users/dashboard-user/components/DashboardUserDetails'));

const AddAgentEntity = lazy(() => import('../../container/users/agent-entity/AddAgent'));
const AgentEntityDetails = lazy(() => import('../../container/users/agent-entity'));
const AgentEntityList = lazy(() => import('../../container/users/agent-entity/AgentEntityList'));

const DirectDelegateList = lazy(() => import('../../container/users/direct-delegate/DirectDelegateList'));
const AddDirectDelegate = lazy(() => import('../../container/users/direct-delegate/AddDirectDelegate'));
const DirectDelegateDetails = lazy(() => import('../../container/users/direct-delegate'));

const AddDirectMerchant = lazy(() => import('../../container/users/direct-merchant/AddDirectMerchant'));
const DirectMerchantDetails = lazy(() => import('../../container/users/direct-merchant'));
const DirectMerchantList = lazy(() => import('../../container/users/direct-merchant/DirectMerchantList'));

const AddUser = lazy(() => import('../../container/users/AddUsers'));
const DataTable = lazy(() => import('../../container/users/UserListDataTable'));
const Team = lazy(() => import('../../container/users/Team'));

// OLD pages
// const Users = lazy(() => import('../../container/users/Users'));
// const AddUser = lazy(() => import('../../container/users/AddUsers'));
// const DataTable = lazy(() => import('../../container/users/UserListDataTable'));
// const Team = lazy(() => import('../../container/users/Team'));
const NotFound = lazy(() => import('../../container/pages/404'));

function UsersRoute() {
  return (
    <Routes>
      {/* Dashboard Admin , and Dashboard  Admin user routes routes */}
      {isUserAuthorized([UserTypes.DASHBOARD_ADMIN_ID, UserTypes.DASHBOARD_USER_ID]) && (
        <>
          <Route path="dashboard-admins/" element={<DashboardAdminList />} />
          <Route path="dashboard-admins/add-user/*" element={<AddDashboardAdmin />} />
          <Route path="dashboard-admins/:id" element={<DashboardAdminDetails />} />
          <Route path="dashboard-admin-users/" element={<DashboardUsersList />} />
          <Route path="dashboard-admin-users/add-user/*" element={<AddDashboardAdminUser />} />
          <Route path="dashboard-admin-users/:id" element={<DashboardUserDetails />} />
        </>
      )}

      {/* Agent Entity routes */}
      <Route path="agent-entities/" element={<AgentEntityList />} />
      <Route path="agent-entities/:id/*" element={<AgentEntityDetails />} />
      {isUserAuthorized([UserTypes.DASHBOARD_ADMIN_ID, UserTypes.DASHBOARD_USER_ID]) && (
        <Route path="agent-entities/add-user/*" element={<AddAgentEntity />} />
      )}

      {/* Merchants Entity routes */}
      <Route path="direct-merchants/" element={<DirectMerchantList />} />
      <Route path="direct-merchants/:id/*" element={<DirectMerchantDetails />} />
      <Route path="direct-merchants/add-user/*" element={<AddDirectMerchant />} />

      {/* Direct delegates */}
      <Route path="direct-delegates/" element={<DirectDelegateList />} />
      <Route path="direct-delegates/add-user/*" element={<AddDirectDelegate />} />
      <Route path="direct-delegates/:id/*" element={<DirectDelegateDetails />} />

      <Route path="add-user/*" element={<AddUser />} />
      <Route path="dataTable" element={<DataTable />} />
      <Route path="team" element={<Team />} />
      {/* OLD routes */}
      {/* <Route path="/*" element={<Users />} />
      <Route path="add-user/*" element={<AddUser />} />
      <Route path="dataTable" element={<DataTable />} />
      <Route path="team" element={<Team />} /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default UsersRoute;
