const actions = {
  // SUPPLIERS ACTIONS
  CREATE_SUPPLIER_BEGIN: 'CREATE_SUPPLIER_BEGIN',
  CREATE_SUPPLIER_SUCCESS: 'CREATE_SUPPLIER_SUCCESS',
  CREATE_SUPPLIER_ERR: 'CREATE_SUPPLIER_ERR',

  LIST_SUPPLIERS_BEGIN: 'LIST_SUPPLIERS_BEGIN',
  LIST_SUPPLIERS_SUCCESS: 'LIST_SUPPLIERS_SUCCESS',
  LIST_SUPPLIERS_ERR: 'LIST_SUPPLIERS_ERR',

  UPDATE_SUPPLIER_BEGIN: 'UPDATE_SUPPLIER_BEGIN',
  UPDATE_SUPPLIER_SUCCESS: 'UPDATE_SUPPLIER_SUCCESS',
  UPDATE_SUPPLIER_ERR: 'UPDATE_SUPPLIER_ERR',

  createSupplierBegin: () => {
    return {
      type: actions.CREATE_SUPPLIER_BEGIN,
    };
  },

  createSupplierSuccess: (data) => {
    return {
      type: actions.CREATE_SUPPLIER_SUCCESS,
      data,
    };
  },

  createSupplierErr: (err) => {
    return {
      type: actions.CREATE_SUPPLIER_ERR,
      err,
    };
  },

  listSuppliersBegin: () => {
    return {
      type: actions.LIST_SUPPLIERS_BEGIN,
    };
  },

  listSuppliersSuccess: (data) => {
    return {
      type: actions.LIST_SUPPLIERS_SUCCESS,
      data,
    };
  },

  listSuppliersErr: (err) => {
    return {
      type: actions.LIST_SUPPLIERS_ERR,
      err,
    };
  },

  updateSupplierBegin: () => {
    return {
      type: actions.UPDATE_SUPPLIER_BEGIN,
    };
  },

  updateSupplierSuccess: (data) => {
    return {
      type: actions.UPDATE_SUPPLIER_SUCCESS,
      data,
    };
  },

  updateSupplierErr: (err) => {
    return {
      type: actions.UPDATE_SUPPLIER_ERR,
      err,
    };
  },
};

export default actions;
