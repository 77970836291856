import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { isUserAuthorized } from '../../common/Authorization';
import { UserTypes } from '../../common/UserTypes';

const NotFound = lazy(() => import('../../container/pages/404'));
const DevicesList = lazy(() => import('../../container/devices'));
const DeviceDetailsTabs = lazy(() => import('../../container/devices/DevicesDetailsTabs'));
const UploadDevices = lazy(() => import('../../container/devices/UploadDevices'));
const MadaRecords = lazy(() => import('../../container/devices/components/MadaRecordsTab'));
const MadaSummaryReports = lazy(() => import('../../container/devices/MadaSummaryReports'));
const MadaRecordDetails = lazy(() => import('../../container/devices/components/MadaRecordDetails'));

function IconsRoute() {
  return (
    <Routes>
      <Route path="/" element={<DevicesList />} />
      <Route path="/mada-records" element={<MadaRecords />} />
      <Route path="/mada-records-summary-report" element={<MadaSummaryReports />} />
      <Route path="/mada-records/:id" element={<MadaRecordDetails />} />
      <Route path="/:id/*" element={<DeviceDetailsTabs />} />
      {isUserAuthorized([UserTypes.DASHBOARD_ADMIN_ID, UserTypes.DASHBOARD_USER_ID]) && (
        <Route path="/add" element={<UploadDevices />} />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default IconsRoute;
