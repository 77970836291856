import actions from './actions';

const {
  LIST_POS_MADA_OPERATIONS_BEGIN,
  LIST_POS_MADA_OPERATIONS_SUCCESS,
  LIST_POS_MADA_OPERATIONS_ERR,
  GET_POS_MADA_OPERATIONS_SUMMARY_REPORT_BEGIN,
  GET_POS_MADA_OPERATIONS_SUMMARY_REPORT_SUCCESS,
  GET_POS_MADA_OPERATIONS_SUMMARY_REPORT_ERR,
} = actions;

const initState = {
  operations: [],
  loadingListPOSMadaOperations: false,
  errorListPOSMadaOperations: '',
  totalOperations: 0,

  // operationDetails: {},
  operationsSummary: [],
  purchaseSummary: {},
  reconciliationSummary: {},
  loadingGetPOSMadaOperationsSummaryReport: false,
  errorGetPOSMadaOperationsSummaryReport: '',
};

const POSMadaReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LIST_POS_MADA_OPERATIONS_BEGIN:
      return {
        ...state,
        loadingListPOSMadaOperations: true,
        errorListPOSMadaOperations: null,
        totalOperations: 0,
      };
    case LIST_POS_MADA_OPERATIONS_SUCCESS:
      return {
        ...state,
        operations: [...data.results],
        loadingListPOSMadaOperations: false,
        errorListPOSMadaOperations: null,
        totalOperations: data.total_count,
      };
    case LIST_POS_MADA_OPERATIONS_ERR:
      return {
        ...state,
        errorListPOSMadaOperations: err,
        loadingListPOSMadaOperations: false,
      };
    case GET_POS_MADA_OPERATIONS_SUMMARY_REPORT_BEGIN:
      return {
        ...state,
        loadingGetPOSMadaOperationsSummaryReport: true,
        errorGetPOSMadaOperationsSummaryReport: null,
      };
    case GET_POS_MADA_OPERATIONS_SUMMARY_REPORT_SUCCESS:
      return {
        ...state,
        purchaseSummary: data.purchase_summary,
        reconciliationSummary: data.reconciliation_summary,
        loadingGetPOSMadaOperationsSummaryReport: false,
        errorGetPOSMadaOperationsSummaryReport: null,
      };
    case GET_POS_MADA_OPERATIONS_SUMMARY_REPORT_ERR:
      return {
        ...state,
        errorGetPOSMadaOperationsSummaryReport: err,
        loadingGetPOSMadaOperationsSummaryReport: false,
      };
    default:
      return state;
  }
};

export { POSMadaReducer };
