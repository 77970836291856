const actions = {
  // POS MADA OPERATIONS ACTIONS
  LIST_POS_MADA_OPERATIONS_BEGIN: 'LIST_POS_MADA_OPERATIONS_BEGIN',
  LIST_POS_MADA_OPERATIONS_SUCCESS: 'LIST_POS_MADA_OPERATIONS_SUCCESS',
  LIST_POS_MADA_OPERATIONS_ERR: 'LIST_POS_MADA_OPERATIONS_ERR',
  GET_POS_MADA_OPERATIONS_SUMMARY_REPORT_BEGIN: 'GET_POS_MADA_OPERATIONS_SUMMARY_REPORT_BEGIN',
  GET_POS_MADA_OPERATIONS_SUMMARY_REPORT_SUCCESS: 'GET_POS_MADA_OPERATIONS_SUMMARY_REPORT_SUCCESS',
  GET_POS_MADA_OPERATIONS_SUMMARY_REPORT_ERR: 'GET_POS_MADA_OPERATIONS_SUMMARY_REPORT_ERR',

  listPOSMadaOperationsBegin: () => {
    return {
      type: actions.LIST_POS_MADA_OPERATIONS_BEGIN,
    };
  },

  listPOSMadaOperationsSuccess: (data) => {
    return {
      type: actions.LIST_POS_MADA_OPERATIONS_SUCCESS,
      data,
    };
  },

  listPOSMadaOperationsErr: (err) => {
    return {
      type: actions.LIST_POS_MADA_OPERATIONS_ERR,
      err,
    };
  },
  getPOSMadaOperationsSummaryReportBegin: () => {
    return {
      type: actions.GET_POS_MADA_OPERATIONS_SUMMARY_REPORT_BEGIN,
    };
  },

  getPOSMadaOperationsSummaryReportSuccess: (data) => {
    return {
      type: actions.GET_POS_MADA_OPERATIONS_SUMMARY_REPORT_SUCCESS,
      data,
    };
  },

  getPOSMadaOperationsSummaryReportErr: (err) => {
    return {
      type: actions.GET_POS_MADA_OPERATIONS_SUMMARY_REPORT_ERR,
      err,
    };
  },
};

export default actions;
