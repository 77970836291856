import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const StockList = lazy(() => import('../../container/stock'));
const AddStock = lazy(() => import('../../container/stock/components/AddStock'));
const StockDetails = lazy(() => import('../../container/stock/components/StockDetails'));
const StockSummaryReport = lazy(() => import('../../container/stock/StockSummaryReport'));

function StocksRoute() {
  return (
    <Routes>
      <Route path="/" element={<StockList />} />
      <Route path="/:id" element={<StockDetails />} />
      <Route path="/add-stock" element={<AddStock />} />
      <Route path="/categories-reports" element={<StockSummaryReport />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default StocksRoute;
