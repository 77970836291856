const actions = {
  // CATEGORIES ACTIONS
  CREATE_CATEGORY_BEGIN: 'CREATE_CATEGORY_BEGIN',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_ERR: 'CREATE_CATEGORY_ERR',

  LIST_CATEGORIES_BEGIN: 'LIST_CATEGORIES_BEGIN',
  LIST_CATEGORIES_SUCCESS: 'LIST_CATEGORIES_SUCCESS',
  LIST_CATEGORIES_ERR: 'LIST_CATEGORIES_ERR',

  GET_CATEGORY_DETAILS_BEGIN: 'GET_CATEGORY_DETAILS_BEGIN',
  GET_CATEGORY_DETAILS_SUCCESS: 'GET_CATEGORY_DETAILS_SUCCESS',
  GET_CATEGORY_DETAILS_ERR: 'GET_CATEGORY_DETAILS_ERR',

  DELETE_CATEGORY_BEGIN: 'DELETE_CATEGORY_BEGIN',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_ERR: 'DELETE_CATEGORY_ERR',

  UPDATE_CATEGORY_BEGIN: 'UPDATE_CATEGORY_BEGIN',
  UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_ERR: 'UPDATE_CATEGORY_ERR',

  ENABLE_CATEGORY_BEGIN: 'ENABLE_CATEGORY_BEGIN',
  ENABLE_CATEGORY_SUCCESS: 'ENABLE_CATEGORY_SUCCESS',
  ENABLE_CATEGORY_ERR: 'ENABLE_CATEGORY_ERR',

  DISABLE_CATEGORY_BEGIN: 'DISABLE_CATEGORY_BEGIN',
  DISABLE_CATEGORY_SUCCESS: 'DISABLE_CATEGORY_SUCCESS',
  DISABLE_CATEGORY_ERR: 'DISABLE_CATEGORY_ERR',

  createCategoryBegin: () => {
    return {
      type: actions.CREATE_CATEGORY_BEGIN,
    };
  },

  createCategorySuccess: (data) => {
    return {
      type: actions.CREATE_CATEGORY_SUCCESS,
      data,
    };
  },

  createCategoryErr: (err) => {
    return {
      type: actions.CREATE_CATEGORY_ERR,
      err,
    };
  },

  listCategoriesBegin: () => {
    return {
      type: actions.LIST_CATEGORIES_BEGIN,
    };
  },

  listCategoriesSuccess: (data) => {
    return {
      type: actions.LIST_CATEGORIES_SUCCESS,
      data,
    };
  },

  listCategoriesErr: (err) => {
    return {
      type: actions.LIST_CATEGORIES_ERR,
      err,
    };
  },

  getCategoryDetailsBegin: () => {
    return {
      type: actions.GET_CATEGORY_DETAILS_BEGIN,
    };
  },

  getCategoryDetailsSuccess: (data) => {
    return {
      type: actions.GET_CATEGORY_DETAILS_SUCCESS,
      data,
    };
  },

  getCategoryDetailsErr: (err) => {
    return {
      type: actions.GET_CATEGORY_DETAILS_ERR,
      err,
    };
  },
  updateCategoryBegin: () => {
    return {
      type: actions.UPDATE_CATEGORY_BEGIN,
    };
  },

  updateCategorySuccess: (data) => {
    return {
      type: actions.UPDATE_CATEGORY_SUCCESS,
      data,
    };
  },

  updateCategoryErr: (err) => {
    return {
      type: actions.UPDATE_CATEGORY_ERR,
      err,
    };
  },

  deleteCategoryBegin: () => {
    return {
      type: actions.DELETE_CATEGORY_BEGIN,
    };
  },

  deleteCategorySuccess: (data) => {
    return {
      type: actions.DELETE_CATEGORY_SUCCESS,
      data,
    };
  },

  deleteCategoryErr: (err) => {
    return {
      type: actions.DELETE_CATEGORY_ERR,
      err,
    };
  },

  enableCategoryBegin: () => {
    return {
      type: actions.ENABLE_CATEGORY_BEGIN,
    };
  },

  enableCategorySuccess: (data) => {
    return {
      type: actions.ENABLE_CATEGORY_SUCCESS,
      data,
    };
  },

  enableCategoryErr: (err) => {
    return {
      type: actions.ENABLE_CATEGORY_ERR,
      err,
    };
  },

  disableCategoryBegin: () => {
    return {
      type: actions.DISABLE_CATEGORY_BEGIN,
    };
  },

  disableCategorySuccess: (data) => {
    return {
      type: actions.DISABLE_CATEGORY_SUCCESS,
      data,
    };
  },

  disableCategoryErr: (err) => {
    return {
      type: actions.DISABLE_CATEGORY_ERR,
      err,
    };
  },
};

export default actions;
