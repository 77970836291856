const actions = {
  CREATE_PRODUCT_BEGIN: 'CREATE_PRODUCT_BEGIN',
  CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
  CREATE_PRODUCT_ERR: 'CREATE_PRODUCT_ERR',

  LIST_PRODUCTS_BEGIN: 'LIST_PRODUCTS_BEGIN',
  LIST_PRODUCTS_SUCCESS: 'LIST_PRODUCTS_SUCCESS',
  LIST_PRODUCTS_ERR: 'LIST_PRODUCTS_ERR',

  LIST_ALL_PRODUCTS_BEGIN: 'LIST_ALL_PRODUCTS_BEGIN',
  LIST_ALL_PRODUCTS_SUCCESS: 'LIST_ALL_PRODUCTS_SUCCESS',
  LIST_ALL_PRODUCTS_ERR: 'LIST_ALL_PRODUCTS_ERR',

  UPDATE_PRODUCT_BEGIN: 'UPDATE_PRODUCT_BEGIN',
  UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_ERR: 'UPDATE_PRODUCT_ERR',

  DELETE_PRODUCT_BEGIN: 'DELETE_PRODUCT_BEGIN',
  DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_ERR: 'DELETE_PRODUCT_ERR',

  GET_PRODUCT_DETAILS_BEGIN: 'GET_PRODUCT_DETAILS_BEGIN',
  GET_PRODUCT_DETAILS_SUCCESS: 'GET_PRODUCT_DETAILS_SUCCESS',
  GET_PRODUCT_DETAILS_ERR: 'GET_PRODUCT_DETAILS_ERR',

  ENABLE_PRODUCT_BEGIN: 'ENABLE_PRODUCT_BEGIN',
  ENABLE_PRODUCT_SUCCESS: 'ENABLE_PRODUCT_SUCCESS',
  ENABLE_PRODUCT_ERR: 'ENABLE_PRODUCT_ERR',

  DISABLE_PRODUCT_BEGIN: 'DISABLE_PRODUCT_BEGIN',
  DISABLE_PRODUCT_SUCCESS: 'DISABLE_PRODUCT_SUCCESS',
  DISABLE_PRODUCT_ERR: 'DISABLE_PRODUCT_ERR',

  // Already came with the template
  SINGLE_PRODUCT_BEGIN: 'SINGLE_PRODUCT_BEGIN',
  SINGLE_PRODUCT_SUCCESS: 'SINGLE_PRODUCT_SUCCESS',
  SINGLE_PRODUCT_ERR: 'SINGLE_PRODUCT_ERR',

  FILTER_PRODUCT_BEGIN: 'FILTER_PRODUCT_BEGIN',
  FILTER_PRODUCT_SUCCESS: 'FILTER_PRODUCT_SUCCESS',
  FILTER_PRODUCT_ERR: 'FILTER_PRODUCT_ERR',

  SORTING_PRODUCT_BEGIN: 'SORTING_PRODUCT_BEGIN',
  SORTING_PRODUCT_SUCCESS: 'SORTING_PRODUCT_SUCCESS',
  SORTING_PRODUCT_ERR: 'SORTING_PRODUCT_ERR',

  LIST_PRODUCT_GROUP_SELLING_PRICES_BEGIN: 'LIST_PRODUCT_GROUP_SELLING_PRICES_BEGIN',
  LIST_PRODUCT_GROUP_SELLING_PRICES_SUCCESS: 'LIST_PRODUCT_GROUP_SELLING_PRICES_SUCCESS',
  LIST_PRODUCT_GROUP_SELLING_PRICES_ERR: 'LIST_PRODUCT_GROUP_SELLING_PRICES_ERR',

  UPDATE_PRODUCT_SELLING_PRICES_BEGIN: 'UPDATE_PRODUCT_SELLING_PRICES_BEGIN',
  UPDATE_PRODUCT_SELLING_PRICES_SUCCESS: 'UPDATE_PRODUCT_SELLING_PRICES_SUCCESS',
  UPDATE_PRODUCT_SELLING_PRICES_ERR: 'UPDATE_PRODUCT_SELLING_PRICES_ERR',
  // Template Actions
  singleProductBegin: () => {
    return {
      type: actions.SINGLE_PRODUCT_BEGIN,
    };
  },

  singleProductSuccess: (data) => {
    return {
      type: actions.SINGLE_PRODUCT_SUCCESS,
      data,
    };
  },

  singleProductErr: (err) => {
    return {
      type: actions.SINGLE_PRODUCT_ERR,
      err,
    };
  },

  filterProductBegin: () => {
    return {
      type: actions.FILTER_PRODUCT_BEGIN,
    };
  },

  filterProductSuccess: (data) => {
    return {
      type: actions.FILTER_PRODUCT_SUCCESS,
      data,
    };
  },

  filterProductErr: (err) => {
    return {
      type: actions.FILTER_PRODUCT_ERR,
      err,
    };
  },

  sortingProductBegin: () => {
    return {
      type: actions.SORTING_PRODUCT_BEGIN,
    };
  },

  sortingProductSuccess: (data) => {
    return {
      type: actions.SORTING_PRODUCT_SUCCESS,
      data,
    };
  },

  sortingProductErr: (err) => {
    return {
      type: actions.SORTING_PRODUCT_ERR,
      err,
    };
  },

  // New Actions
  createProductBegin: () => {
    return {
      type: actions.CREATE_PRODUCT_BEGIN,
    };
  },

  createProductSuccess: (data) => {
    return {
      type: actions.CREATE_PRODUCT_SUCCESS,
      data,
    };
  },

  createProductErr: (err) => {
    return {
      type: actions.CREATE_PRODUCT_ERR,
      err,
    };
  },

  listProductsBegin: () => {
    return {
      type: actions.LIST_PRODUCTS_BEGIN,
    };
  },

  listProductsSuccess: (data) => {
    return {
      type: actions.LIST_PRODUCTS_SUCCESS,
      data,
    };
  },

  listProductsErr: (err) => {
    return {
      type: actions.LIST_PRODUCTS_ERR,
      err,
    };
  },

  updateProductBegin: () => {
    return {
      type: actions.UPDATE_PRODUCT_BEGIN,
    };
  },

  updateProductSuccess: (data) => {
    return {
      type: actions.UPDATE_PRODUCT_SUCCESS,
      data,
    };
  },

  updateProductErr: (err) => {
    return {
      type: actions.UPDATE_PRODUCT_ERR,
      err,
    };
  },

  deleteProductBegin: () => {
    return {
      type: actions.DELETE_PRODUCT_BEGIN,
    };
  },

  deleteProductSuccess: (data) => {
    return {
      type: actions.DELETE_PRODUCT_SUCCESS,
      data,
    };
  },

  deleteProductErr: (err) => {
    return {
      type: actions.DELETE_PRODUCT_ERR,
      err,
    };
  },

  getProductDetailsBegin: () => {
    return {
      type: actions.GET_PRODUCT_DETAILS_BEGIN,
    };
  },

  getProductDetailsSuccess: (data) => {
    return {
      type: actions.GET_PRODUCT_DETAILS_SUCCESS,
      data,
    };
  },

  getProductDetailsErr: (err) => {
    return {
      type: actions.GET_PRODUCT_DETAILS_ERR,
      err,
    };
  },

  enableProductBegin: () => {
    return {
      type: actions.ENABLE_PRODUCT_BEGIN,
    };
  },

  enableProductSuccess: (data) => {
    return {
      type: actions.ENABLE_PRODUCT_SUCCESS,
      data,
    };
  },

  enableProductErr: (err) => {
    return {
      type: actions.ENABLE_PRODUCT_ERR,
      err,
    };
  },

  disableProductBegin: () => {
    return {
      type: actions.DISABLE_PRODUCT_BEGIN,
    };
  },

  disableProductSuccess: (data) => {
    return {
      type: actions.DISABLE_PRODUCT_SUCCESS,
      data,
    };
  },

  disableProductErr: (err) => {
    return {
      type: actions.DISABLE_PRODUCT_ERR,
      err,
    };
  },

  listProductSellingPricesBegin: () => {
    return {
      type: actions.LIST_PRODUCT_GROUP_SELLING_PRICES_BEGIN,
    };
  },

  listProductSellingPricesSuccess: (data) => {
    return {
      type: actions.LIST_PRODUCT_GROUP_SELLING_PRICES_SUCCESS,
      data,
    };
  },

  listProductSellingPricesErr: (err) => {
    return {
      type: actions.LIST_PRODUCT_GROUP_SELLING_PRICES_ERR,
      err,
    };
  },

  updateProductSellingPricesBegin: () => {
    return {
      type: actions.UPDATE_PRODUCT_SELLING_PRICES_BEGIN,
    };
  },

  updateProductSellingPricesSuccess: (data) => {
    return {
      type: actions.UPDATE_PRODUCT_SELLING_PRICES_SUCCESS,
      data,
    };
  },

  updateProductSellingPricesErr: (err) => {
    return {
      type: actions.UPDATE_PRODUCT_SELLING_PRICES_ERR,
      err,
    };
  },

  listAllProductsBegin: () => {
    return {
      type: actions.LIST_ALL_PRODUCTS_BEGIN,
    };
  },

  listAllProductsSuccess: (data) => {
    return {
      type: actions.LIST_ALL_PRODUCTS_SUCCESS,
      data,
    };
  },

  listAllProductsErr: (err) => {
    return {
      type: actions.LIST_ALL_PRODUCTS_ERR,
      err,
    };
  },
};

export default actions;
