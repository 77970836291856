import { MenuSettings, theme } from './theme/themeVariables';
import { getItem } from '../utility/localStorageControl';
import { isUserAuthorized } from '../common/Authorization';
import { UserTypes } from '../common/UserTypes';

const config = {
  // topMenu: getItem(MenuSettings.TOP) || false,
  topMenu: true,
  rtl: getItem(MenuSettings.RTL) || false,
  mainTemplate: isUserAuthorized([UserTypes.AGENT_OWNER_ACCOUNT_ID, UserTypes.AGENT_USER_ID])
    ? 'agencyLightMode'
    : getItem(MenuSettings.DARK)
    ? 'darkMode'
    : 'lightMode',
  theme,
};

export default config;
