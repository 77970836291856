const actions = {
  OTP_BEGIN: 'OTP_BEGIN',
  OTP_SUCCESS: 'OTP_SUCCESS',
  OTP_ERR: 'OTP_ERR',

  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  otpBegin: () => {
    return {
      type: actions.OTP_BEGIN,
    };
  },

  otpSuccess: (data) => {
    return {
      type: actions.OTP_SUCCESS,
      data,
    };
  },

  otpErr: (err) => {
    return {
      type: actions.OTP_ERR,
      err,
    };
  },

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: (data) => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: (err) => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: (data) => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: (err) => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },
};

export default actions;
