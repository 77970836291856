const actions = {
  // MERCHANT GROUP ACTIONS
  LIST_MERCHANT_GROUPS_BEGIN: 'LIST_MERCHANT_GROUPS_BEGIN',
  LIST_MERCHANT_GROUPS_SUCCESS: 'LIST_MERCHANT_GROUPS_SUCCESS',
  LIST_MERCHANT_GROUPS_ERR: 'LIST_MERCHANT_GROUPS_ERR',

  ADD_MERCHANT_GROUP_BEGIN: 'ADD_MERCHANT_GROUP_BEGIN',
  ADD_MERCHANT_GROUP_SUCCESS: 'ADD_MERCHANT_GROUP_SUCCESS',
  ADD_MERCHANT_GROUP_ERR: 'ADD_MERCHANT_GROUP_ERR',

  UPDATE_MERCHANT_GROUP_BEGIN: 'UPDATE_MERCHANT_GROUP_BEGIN',
  UPDATE_MERCHANT_GROUP_SUCCESS: 'UPDATE_MERCHANT_GROUP_SUCCESS',
  UPDATE_MERCHANT_GROUP_ERR: 'UPDATE_MERCHANT_GROUP_ERR',

  ENABLE_MERCHANT_GROUP_BEGIN: 'ENABLE_MERCHANT_GROUP_BEGIN',
  ENABLE_MERCHANT_GROUP_SUCCESS: 'ENABLE_MERCHANT_GROUP_SUCCESS',
  ENABLE_MERCHANT_GROUP_ERR: 'ENABLE_MERCHANT_GROUP_ERR',

  DISABLE_MERCHANT_GROUP_BEGIN: 'DISABLE_MERCHANT_GROUP_BEGIN',
  DISABLE_MERCHANT_GROUP_SUCCESS: 'DISABLE_MERCHANT_GROUP_SUCCESS',
  DISABLE_MERCHANT_GROUP_ERR: 'DISABLE_MERCHANT_GROUP_ERR',

  DELETE_MERCHANT_GROUP_BEGIN: 'DELETE_MERCHANT_GROUP_BEGIN',
  DELETE_MERCHANT_GROUP_SUCCESS: 'DELETE_MERCHANT_GROUP_SUCCESS',
  DELETE_MERCHANT_GROUP_ERR: 'DELETE_MERCHANT_GROUP_ERR',

  addMerchantGroupBegin: () => {
    return {
      type: actions.ADD_MERCHANT_GROUP_BEGIN,
    };
  },

  addMerchantGroupSuccess: (data) => {
    return {
      type: actions.ADD_MERCHANT_GROUP_SUCCESS,
      data,
    };
  },

  addMerchantGroupErr: (err) => {
    return {
      type: actions.ADD_MERCHANT_GROUP_ERR,
      err,
    };
  },

  listMerchantGroupsBegin: () => {
    return {
      type: actions.LIST_MERCHANT_GROUPS_BEGIN,
    };
  },

  listMerchantGroupsSuccess: (data) => {
    return {
      type: actions.LIST_MERCHANT_GROUPS_SUCCESS,
      data,
    };
  },

  listMerchantGroupsErr: (err) => {
    return {
      type: actions.LIST_MERCHANT_GROUPS_ERR,
      err,
    };
  },

  updateMerchantGroupBegin: () => {
    return {
      type: actions.UPDATE_MERCHANT_GROUP_BEGIN,
    };
  },

  updateMerchantGroupSuccess: (data) => {
    return {
      type: actions.UPDATE_MERCHANT_GROUP_SUCCESS,
      data,
    };
  },

  updateMerchantGroupErr: (err) => {
    return {
      type: actions.UPDATE_MERCHANT_GROUP_ERR,
      err,
    };
  },

  enableMerchantGroupBegin: () => {
    return {
      type: actions.ENABLE_MERCHANT_GROUP_BEGIN,
    };
  },

  enableMerchantGroupSuccess: (data) => {
    return {
      type: actions.ENABLE_MERCHANT_GROUP_SUCCESS,
      data,
    };
  },

  enableMerchantGroupErr: (err) => {
    return {
      type: actions.ENABLE_MERCHANT_GROUP_ERR,
      err,
    };
  },

  disableMerchantGroupBegin: () => {
    return {
      type: actions.DISABLE_MERCHANT_GROUP_BEGIN,
    };
  },

  disableMerchantGroupSuccess: (data) => {
    return {
      type: actions.DISABLE_MERCHANT_GROUP_SUCCESS,
      data,
    };
  },

  disableMerchantGroupErr: (err) => {
    return {
      type: actions.DISABLE_MERCHANT_GROUP_ERR,
      err,
    };
  },

  deleteMerchantGroupBegin: () => {
    return {
      type: actions.DELETE_MERCHANT_GROUP_BEGIN,
    };
  },

  deleteMerchantGroupSuccess: (data) => {
    return {
      type: actions.DELETE_MERCHANT_GROUP_SUCCESS,
      data,
    };
  },

  deleteMerchantGroupErr: (err) => {
    return {
      type: actions.DELETE_MERCHANT_GROUP_ERR,
      err,
    };
  },
};

export default actions;
