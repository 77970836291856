import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const OrderDetailsTabs = lazy(() => import('../../container/sales/components/OrderDetailsTabs'));
const OrdersList = lazy(() => import('../../container/sales'));
const CardCategoriesSales = lazy(() => import('../../container/sales/CardsCategoriesSales'));

function SalesRoute() {
  return (
    <Routes>
      <Route path="/orders" element={<OrdersList />} />
      <Route path="/orders/:id/*" element={<OrderDetailsTabs />} />
      <Route path="/card-categories-sales" element={<CardCategoriesSales />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default SalesRoute;
