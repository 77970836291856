import actions from './actions';

const {
  LIST_PRODUCT_STOCK_BEGIN,
  LIST_PRODUCT_STOCK_SUCCESS,
  LIST_PRODUCT_STOCK_ERR,
  GET_PRODUCT_STOCK_DETAILS_BEGIN,
  GET_PRODUCT_STOCK_DETAILS_SUCCESS,
  GET_PRODUCT_STOCK_DETAILS_ERR,
  GET_STOCK_SUMMARY_REPORT_BEGIN,
  GET_STOCK_SUMMARY_REPORT_SUCCESS,
  GET_STOCK_SUMMARY_REPORT_ERR,
  ADD_PRODUCT_STOCK_BEGIN,
  ADD_PRODUCT_STOCK_SUCCESS,
  ADD_PRODUCT_STOCK_ERR,
  REMOVE_PRODUCT_STOCK_BEGIN,
  REMOVE_PRODUCT_STOCK_SUCCESS,
  REMOVE_PRODUCT_STOCK_ERR,
  GENERATE_KARZ_STOCK_BEGIN,
  GENERATE_KARZ_STOCK_SUCCESS,
  GENERATE_KARZ_STOCK_ERR,
} = actions;

const initState = {
  // Brands
  loadingAddProductStock: false,
  errorAddProductStock: '',
  productStock: [],

  loadingListProductStock: false,
  errorListProductStock: null,
  totalStock: 0,
  totalStockPages: 0,

  loadingGetProductStockDetails: false,
  errorGetProductStockDetails: null,
  productStockDetails: {},

  loadingRemoveProductStock: false,
  errorRemoveProductStock: '',

  loadingGenerateKARZStock: false,
  errorGenerateKARZStock: null,
  KARZStock: [],

  loadingGetStockSummaryReport: false,
  errorGetStockSummaryReport: '',
  stockSummaryReport: [],
  totalCostPrice: 0,
  totalAvgCostPrice: 0,
};

const stockReducer = (state = initState, action) => {
  const { type, data, err } = action;

  switch (type) {
    // Brands
    case ADD_PRODUCT_STOCK_BEGIN:
      return {
        ...state,
        loadingAddProductStock: true,
        errorAddProductStock: '',
      };
    case ADD_PRODUCT_STOCK_SUCCESS:
      return {
        ...state,
        productStock: [...state.productStock, data],
        loadingAddProductStock: false,
        errorAddProductStock: '',
      };
    case ADD_PRODUCT_STOCK_ERR:
      return {
        ...state,
        loadingAddProductStock: false,
        errorAddProductStock: err,
      };

    case LIST_PRODUCT_STOCK_BEGIN:
      return {
        ...state,
        totalStock: 0,
        totalStockPages: 0,
        loadingListProductStock: true,
        errorListProductStock: null,
      };
    case LIST_PRODUCT_STOCK_SUCCESS:
      return {
        ...state,
        productStock: [...data.results],
        totalStock: data.total_count,
        totalStockPages: data.total_pages,
        loadingListProductStock: false,
        errorListProductStock: null,
      };
    case LIST_PRODUCT_STOCK_ERR:
      return {
        ...state,
        totalStock: 0,
        totalStockPages: 0,
        loadingListProductStock: false,
        errorListProductStock: err,
      };

    case GET_PRODUCT_STOCK_DETAILS_BEGIN:
      return {
        ...state,
        productStockDetails: [],
        loadingGetProductStockDetails: true,
        errorGetProductStockDetails: null,
      };
    case GET_PRODUCT_STOCK_DETAILS_SUCCESS:
      return {
        ...state,
        productStockDetails: data,
        loadingGetProductStockDetails: false,
        errorGetProductStockDetails: null,
      };
    case GET_PRODUCT_STOCK_DETAILS_ERR:
      return {
        ...state,
        loadingGetProductStockDetails: false,
        errorGetProductStockDetails: err,
        productStockDetails: [],
      };

    case REMOVE_PRODUCT_STOCK_BEGIN:
      return {
        ...state,
        loadingRemoveProductStock: true,
        errorRemoveProductStock: '',
      };
    case REMOVE_PRODUCT_STOCK_SUCCESS:
      return {
        ...state,
        loadingRemoveProductStock: false,
        errorRemoveProductStock: '',
      };
    case REMOVE_PRODUCT_STOCK_ERR:
      return {
        ...state,
        loadingRemoveProductStock: false,
        errorRemoveProductStock: err,
      };

    case GET_STOCK_SUMMARY_REPORT_BEGIN:
      return {
        ...state,
        stockSummaryReport: [],
        loadingGetStockSummaryReport: true,
        errorGetStockSummaryReport: null,
      };
    case GET_STOCK_SUMMARY_REPORT_SUCCESS:
      return {
        ...state,
        stockSummaryReport: [...data.results],
        totalCostPrice: data.total_cost_price,
        loadingGetStockSummaryReport: false,
        errorGetStockSummaryReport: null,
        totalAvgCostPrice: data.total_avg_cost_price,
      };
    case GET_STOCK_SUMMARY_REPORT_ERR:
      return {
        ...state,
        loadingGetStockSummaryReport: false,
        errorGetStockSummaryReport: err,
        stockSummaryReport: [],
      };

    // Brand Categories
    case GENERATE_KARZ_STOCK_BEGIN:
      return {
        ...state,
        loadingGenerateKARZStock: true,
        errorGenerateKARZStock: '',
      };
    case GENERATE_KARZ_STOCK_SUCCESS:
      return {
        ...state,
        KARZStock: [...state.KARZStock, data],
        loadingGenerateKARZStock: false,
        errorGenerateKARZStock: '',
      };
    case GENERATE_KARZ_STOCK_ERR:
      return {
        ...state,
        loadingGenerateKARZStock: false,
        errorGenerateKARZStock: err,
      };

    default:
      return state;
  }
};

export { stockReducer };
