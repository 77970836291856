import Cookies from 'js-cookie';
import { t } from 'i18next';
import { UserTypes } from './UserTypes';

const loggedInAccount = Cookies?.get('account') && JSON.parse(Cookies?.get('account') || '');

const accountTypeId = loggedInAccount?.account_type_id;

const isUserNotAuthorized = (restrictedUserTypes) => {
  return restrictedUserTypes?.includes(accountTypeId);
};

const isUserAuthorized = (allowedUserTypes) => {
  return allowedUserTypes?.includes(accountTypeId);
};

const displayUserType = (accountTypeID) => {
  switch (accountTypeID) {
    case UserTypes.AGENT_ENTITY_ID:
      return t('userTypes.agentEntity');
    case UserTypes.DIRECT_MERCHANT_ID:
      return t('userTypes.merchant');
    case UserTypes.DIRECT_MERCHANT_USER_ID:
      return t('userTypes.merchantUser');
    case UserTypes.DIRECT_DELEGATE_ID:
      return t('userTypes.directDelegate');
    default:
      return 'comming';
  }
};

export { isUserNotAuthorized, isUserAuthorized, loggedInAccount, displayUserType };
