import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const { otpBegin, otpSuccess, otpErr, loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } =
  actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/auth/mobile_login', {
        ...values,
        mobile_number: Cookies.get('loginMobile'),
      });

      if (response.status !== 200 && response.data.message) {
        dispatch(loginErr(response.data.message));
      } else {
        Cookies.set('access_token', response.data.access_token);
        Cookies.set('access_token_expiration_date', response.data.expiration_date);
        Cookies.set('logedIn', true);
        Cookies.set('account', JSON.stringify(response.data.account));
        dispatch(loginSuccess({ isLoggedIn: true, profile: response.data.account }));
        callback();
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const sendLoginOTP = (values, callback) => {
  return async (dispatch) => {
    dispatch(otpBegin());
    try {
      const response = await DataService.post('/auth/otp', { mobile_number: values.mobile });
      if (response.status !== 200 && response.data.message) {
        dispatch(otpErr(response.data.message));
      } else {
        Cookies.set('loginMobile', values.mobile);

        dispatch(otpSuccess(true));
        callback();
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/register', values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        dispatch(loginSuccess(false));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());

    try {
      const response = await DataService.delete('/auth/logout');
      Cookies.remove('logedIn');
      Cookies.remove('account');
      Cookies.remove('access_token');
      Cookies.remove('loginMobile');
      Cookies.remove('access_token_expiration_date');
      callback();

      if (response.status !== 200 && response.data.message) {
        dispatch(logoutErr(response.data.message));
      } else {
        dispatch(logoutSuccess(false));
      }
    } catch (err) {
      callback();
      console.log('Error loging out: ', err);
      dispatch(logoutErr(err));
    }
  };
};

export { sendLoginOTP, login, logOut, register };
