// // Define user types
export const UserTypes = {
  DASHBOARD_ADMIN_ID: 1,
  DASHBOARD_USER_ID: 2,
  AGENT_ENTITY_ID: 10,
  AGENT_OWNER_ACCOUNT_ID: 11,
  AGENT_USER_ID: 12,
  DIRECT_DELEGATE_ID: 8,
  DIRECT_MERCHANT_ID: 20,
  DIRECT_MERCHANT_USER_ID: 22,
};
/*
exports.AccountTypes = {
  DASHBOARD_ADMIN_ACCOUNT: 1, // No wallet
  DASHBOARD_USER_ACCOUNT: 2, // No wallet

  DELEGATE_USER_ACCOUNT: 8, // wallet
  PUBLIC_USER_ACCOUNT: 9, // wallet

  AGENT_ENTITY_ACCOUNT: 10, // agent entity account, no login, direct wallet
  AGENT_OWNER_ACCOUNT: 11, // No personal wallet, sharing agent entity wallet
  AGENT_USER_ACCOUNT: 12, // No personal wallet, sharing agent entity wallet

  MERCHANT_ENTITY_ACCOUNT: 20, // merchant entity account, no login, direct wallet
  MERCHANT_OWNER_ACCOUNT: 21, // No personal wallet, sharing merchant entity wallet
  MERCHANT_USER_ACCOUNT: 22, // No personal wallet, sharing merchant entity wallet
};
*/
