import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const CreateMerchantGroupForm = lazy(() => import('../../container/merchantGroups/CreateMerchantGroupForm'));
const EditMerchantGroupForm = lazy(() => import('../../container/merchantGroups/EditMerchantGroupForm'));
const MerchantGroupsList = lazy(() => import('../../container/merchantGroups'));

function MerchantGroupsRoute() {
  return (
    <Routes>
      <Route path="/" element={<MerchantGroupsList />} />
      <Route path="/add-merchant-group" element={<CreateMerchantGroupForm />} />
      <Route path="/:id/edit" element={<EditMerchantGroupForm />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default MerchantGroupsRoute;
