import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const CreateBrandForm = lazy(() => import('../../container/brands/CreateBrandForm'));
const BrandsList = lazy(() => import('../../container/brands'));

const BrandTabs = lazy(() => import('../../container/brands/BrandDetailsTabs'));
const CardDetailsTabs = lazy(() => import('../../container/brands/cards/CardDetailsTabs'));

function IconsRoute() {
  return (
    <Routes>
      <Route path="/" element={<BrandsList />} />
      <Route path="/add-brand" element={<CreateBrandForm />} />
      <Route path="/brand-categories" element={<CreateBrandForm />} />
      <Route path="/:id/products/:productId/*" element={<CardDetailsTabs />} />

      <Route path="/:id/*" element={<BrandTabs />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default IconsRoute;
