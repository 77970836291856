import actions from './actions';

const {
  CREATE_BRAND_BEGIN,
  CREATE_BRAND_SUCCESS,
  CREATE_BRAND_ERR,
  LIST_BRANDS_BEGIN,
  LIST_BRANDS_SUCCESS,
  LIST_BRANDS_ERR,
  GET_BRAND_DETAILS_BEGIN,
  GET_BRAND_DETAILS_SUCCESS,
  GET_BRAND_DETAILS_ERR,
  UPDATE_BRAND_BEGIN,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_ERR,
  DELETE_BRAND_BEGIN,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_ERR,
  CREATE_BRAND_CATEGORY_BEGIN,
  CREATE_BRAND_CATEGORY_SUCCESS,
  CREATE_BRAND_CATEGORY_ERR,
  LIST_BRAND_CATEGORIES_BEGIN,
  LIST_BRAND_CATEGORIES_SUCCESS,
  LIST_BRAND_CATEGORIES_ERR,
  UPDATE_BRAND_CATEGORY_BEGIN,
  UPDATE_BRAND_CATEGORY_SUCCESS,
  UPDATE_BRAND_CATEGORY_ERR,
  DELETE_BRAND_CATEGORY_BEGIN,
  DELETE_BRAND_CATEGORY_SUCCESS,
  DELETE_BRAND_CATEGORY_ERR,
  ENABLE_BRAND_BEGIN,
  ENABLE_BRAND_SUCCESS,
  ENABLE_BRAND_ERR,
  DISABLE_BRAND_BEGIN,
  DISABLE_BRAND_SUCCESS,
  DISABLE_BRAND_ERR,
  ENABLE_BRAND_CATEGORY_BEGIN,
  ENABLE_BRAND_CATEGORY_SUCCESS,
  ENABLE_BRAND_CATEGORY_ERR,
  DISABLE_BRAND_CATEGORY_BEGIN,
  DISABLE_BRAND_CATEGORY_SUCCESS,
  DISABLE_BRAND_CATEGORY_ERR,
} = actions;

const initState = {
  // Brands
  loadingCreateBrand: false,
  errorCreateBrand: '',
  brands: [],
  loadingListBrands: false,
  errorListBrands: null,
  loadingUpdateBrand: false,
  errorUpdateBrand: false,
  loadingDeleteBrand: false,
  errorDeleteBrand: false,
  // Brand Categories
  loadingCreateBrandCategory: false,
  errorCreateBrandCategory: '',
  brandCategories: [],
  loadingListBrandCategories: false,
  errorListBrandCategories: null,
  loadingUpdateBrandCategory: false,
  errorUpdateBrandCategory: false,
  loadingDeleteBrandCategory: false,
  errorDeleteBrandCategory: false,
  loadingGetBrandDetails: false,
  errorGetBrandDetails: null,
  brandDetails: {},

  loadingEnableBrand: false,
  errorEnableBrand: null,
  loadingDisableBrand: false,
  errorDisableBrand: null,
  loadingEnableBrandCategory: false,
  errorEnableBrandCategory: false,
  loadingDisableBrandCategory: false,
  errorDisableBrandCategory: false,
};

const brandReducer = (state = initState, action) => {
  const { type, data, err } = action;

  switch (type) {
    // Brands
    case CREATE_BRAND_BEGIN:
      return {
        ...state,
        loadingCreateBrand: true,
        errorCreateBrand: '',
      };
    case CREATE_BRAND_SUCCESS:
      return {
        ...state,
        brands: [...state.brands, data],
        loadingCreateBrand: false,
        errorCreateBrand: '',
      };
    case CREATE_BRAND_ERR:
      return {
        ...state,
        errorCreateBrand: err,
        loadingCreateBrand: false,
      };

    case LIST_BRANDS_BEGIN:
      return {
        ...state,
        loadingListBrands: true,
        errorListBrands: null,
      };
    case LIST_BRANDS_SUCCESS:
      return {
        ...state,
        brands: [...data],
        loadingListBrands: false,
        errorListBrands: null,
      };
    case LIST_BRANDS_ERR:
      return {
        ...state,
        errorListBrands: err,
        loadingListBrands: false,
      };

    case GET_BRAND_DETAILS_BEGIN:
      return {
        ...state,
        loadingGetBrandDetails: true,
        errorGetBrandDetails: null,
      };
    case GET_BRAND_DETAILS_SUCCESS:
      return {
        ...state,
        brandDetails: data,
        loadingGetBrandDetails: false,
        errorGetBrandDetails: null,
      };
    case GET_BRAND_DETAILS_ERR:
      return {
        ...state,
        errorGetBrandDetails: err,
        loadingGetBrandDetails: false,
      };

    case UPDATE_BRAND_BEGIN:
      return {
        ...state,
        loadingUpdateBrand: true,
        errorUpdateBrand: null,
      };
    case UPDATE_BRAND_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedList = state.brands.map((item) => (item.app_category_id === data.app_category_id ? data : item));

      return {
        ...state,
        brands: [...updatedList],
        loadingUpdateBrand: false,
        errorUpdateBrand: null,
      };
    case UPDATE_BRAND_ERR:
      return {
        ...state,
        errorUpdateBrand: err,
        loadingUpdateBrand: false,
      };

    case DELETE_BRAND_BEGIN:
      return {
        ...state,
        loadingDeleteBrand: true,
        errorDeleteBrand: null,
      };
    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        loadingDeleteBrand: false,
        errorDeleteBrand: null,
      };
    case DELETE_BRAND_ERR:
      return {
        ...state,
        errorDeleteBrand: err,
        loadingDeleteBrand: false,
      };
    case ENABLE_BRAND_BEGIN:
      return {
        ...state,
        loadingEnableBrand: true,
        errorEnableBrand: null,
      };
    case ENABLE_BRAND_SUCCESS:
      return {
        ...state,
        loadingEnableBrand: false,
        errorEnableBrand: null,
      };
    case ENABLE_BRAND_ERR:
      return {
        ...state,
        errorEnableBrand: err,
        loadingEnableBrand: false,
      };
    case DISABLE_BRAND_BEGIN:
      return {
        ...state,
        loadingDisableBrand: true,
        errorDisableBrand: null,
      };
    case DISABLE_BRAND_SUCCESS:
      return {
        ...state,
        loadingDisableBrand: false,
        errorDisableBrand: null,
      };
    case DISABLE_BRAND_ERR:
      return {
        ...state,
        errorDisableBrand: err,
        loadingDisableBrand: false,
      };

    // Brand Categories
    case CREATE_BRAND_CATEGORY_BEGIN:
      return {
        ...state,
        loadingCreateBrandCategory: true,
        errorCreateBrandCategory: '',
      };
    case CREATE_BRAND_CATEGORY_SUCCESS:
      return {
        ...state,
        brandCategories: [...state.brandCategories, data],
        loadingCreateBrandCategory: false,
        errorCreateBrandCategory: '',
      };
    case CREATE_BRAND_CATEGORY_ERR:
      return {
        ...state,
        errorCreateBrandCategory: err,
        loadingCreateBrandCategory: false,
      };

    case LIST_BRAND_CATEGORIES_BEGIN:
      return {
        ...state,
        loadingListBrandCategories: true,
        errorListBrandCategories: null,
      };
    case LIST_BRAND_CATEGORIES_SUCCESS:
      return {
        ...state,
        brandCategories: [...data],
        loadingListBrandCategories: false,
        errorListBrandCategories: null,
      };
    case LIST_BRAND_CATEGORIES_ERR:
      return {
        ...state,
        errorListBrandCategories: err,
        loadingListBrandCategories: false,
      };

    case UPDATE_BRAND_CATEGORY_BEGIN:
      return {
        ...state,
        loadingUpdateBrandCategory: true,
        errorUpdateBrandCategory: null,
      };
    case UPDATE_BRAND_CATEGORY_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedBrandCategoriesList = state.brandCategories.map((item) =>
        item.app_category_id === data.app_category_id ? data : item,
      );

      return {
        ...state,
        brandCategories: [...updatedBrandCategoriesList],
        loadingUpdateBrandCategory: false,
        errorUpdateBrandCategory: null,
      };
    case UPDATE_BRAND_CATEGORY_ERR:
      return {
        ...state,
        errorUpdateBrandCategory: err,
        loadingUpdateBrandCategory: false,
      };

    case DELETE_BRAND_CATEGORY_BEGIN:
      return {
        ...state,
        loadingDeleteBrandCategory: true,
        errorDeleteBrandCategory: null,
      };
    case DELETE_BRAND_CATEGORY_SUCCESS:
      return {
        ...state,
        loadingDeleteBrandCategory: false,
        errorDeleteBrandCategory: null,
      };
    case DELETE_BRAND_CATEGORY_ERR:
      return {
        ...state,
        errorDeleteBrandCategory: err,
        loadingDeleteBrandCategory: false,
      };

    case ENABLE_BRAND_CATEGORY_BEGIN:
      return {
        ...state,
        loadingEnableBrandCategory: true,
        errorEnableBrandCategory: null,
      };
    case ENABLE_BRAND_CATEGORY_SUCCESS:
      return {
        ...state,
        loadingEnableBrandCategory: false,
        errorEnableBrandCategory: null,
      };
    case ENABLE_BRAND_CATEGORY_ERR:
      return {
        ...state,
        errorEnableBrandCategory: err,
        loadingEnableBrandCategory: false,
      };

    case DISABLE_BRAND_CATEGORY_BEGIN:
      return {
        ...state,
        loadingDisableBrandCategory: true,
        errorDisableBrandCategory: null,
      };
    case DISABLE_BRAND_CATEGORY_SUCCESS:
      return {
        ...state,
        loadingDisableBrandCategory: false,
        errorDisableBrandCategory: null,
      };
    case DISABLE_BRAND_CATEGORY_ERR:
      return {
        ...state,
        errorDisableBrandCategory: err,
        loadingDisableBrandCategory: false,
      };

    default:
      return state;
  }
};

export { brandReducer };
