/* eslint-disable no-case-declarations */
import actions from './actions';
import initialState from '../../demoData/usersData.json';
import initialStateGroup from '../../demoData/usersGroupData.json';

const {
  CREATE_DASHBOARD_ADMIN_BEGIN,
  CREATE_DASHBOARD_ADMIN_SUCCESS,
  CREATE_DASHBOARD_ADMIN_ERR,
  CREATE_DASHBOARD_USER_BEGIN,
  CREATE_DASHBOARD_USER_SUCCESS,
  CREATE_DASHBOARD_USER_ERR,
  LIST_DASHBOARD_ADMINS_BEGIN,
  LIST_DASHBOARD_ADMINS_SUCCESS,
  LIST_DASHBOARD_ADMINS_ERR,
  LIST_DASHBOARD_USERS_BEGIN,
  LIST_DASHBOARD_USERS_SUCCESS,
  LIST_DASHBOARD_USERS_ERR,
  CREATE_AGENT_ENTITY_BEGIN,
  CREATE_AGENT_ENTITY_SUCCESS,
  CREATE_AGENT_ENTITY_ERR,
  CREATE_AGENT_USER_BEGIN,
  CREATE_AGENT_USER_SUCCESS,
  CREATE_AGENT_USER_ERR,
  LIST_AGENT_ENTITIES_BEGIN,
  LIST_AGENT_ENTITIES_SUCCESS,
  LIST_AGENT_ENTITIES_ERR,
  LIST_AGENT_USERS_BEGIN,
  LIST_AGENT_USERS_SUCCESS,
  LIST_AGENT_USERS_ERR,
  CREATE_DIRECT_DELEGATE_BEGIN,
  CREATE_DIRECT_DELEGATE_SUCCESS,
  CREATE_DIRECT_DELEGATE_ERR,
  LIST_DIRECT_DELEGATES_BEGIN,
  LIST_DIRECT_DELEGATES_SUCCESS,
  LIST_DIRECT_DELEGATES_ERR,
  CREATE_DIRECT_MERCHANT_BEGIN,
  CREATE_DIRECT_MERCHANT_SUCCESS,
  CREATE_DIRECT_MERCHANT_ERR,
  LIST_DIRECT_MERCHANTS_BEGIN,
  LIST_DIRECT_MERCHANTS_SUCCESS,
  LIST_DIRECT_MERCHANTS_ERR,
  CREATE_DIRECT_MERCHANT_USER_BEGIN,
  CREATE_DIRECT_MERCHANT_USER_SUCCESS,
  CREATE_DIRECT_MERCHANT_USER_ERR,
  LIST_DIRECT_MERCHANT_USERS_BEGIN,
  LIST_DIRECT_MERCHANT_USERS_SUCCESS,
  LIST_DIRECT_MERCHANT_USERS_ERR,
  UPDATE_DASHBOARD_ADMIN_BEGIN,
  UPDATE_DASHBOARD_ADMIN_SUCCESS,
  UPDATE_DASHBOARD_ADMIN_ERR,
  UPDATE_DASHBOARD_USER_BEGIN,
  UPDATE_DASHBOARD_USER_SUCCESS,
  UPDATE_DASHBOARD_USER_ERR,
  UPDATE_AGENT_ENTITY_BEGIN,
  UPDATE_AGENT_ENTITY_SUCCESS,
  UPDATE_AGENT_ENTITY_ERR,
  UPDATE_AGENT_USER_BEGIN,
  UPDATE_AGENT_USER_SUCCESS,
  UPDATE_AGENT_USER_ERR,
  UPDATE_DIRECT_DELEGATE_BEGIN,
  UPDATE_DIRECT_DELEGATE_SUCCESS,
  UPDATE_DIRECT_DELEGATE_ERR,
  UPDATE_DIRECT_MERCHANT_BEGIN,
  UPDATE_DIRECT_MERCHANT_SUCCESS,
  UPDATE_DIRECT_MERCHANT_ERR,
  UPDATE_DIRECT_MERCHANT_USER_BEGIN,
  UPDATE_DIRECT_MERCHANT_USER_SUCCESS,
  UPDATE_DIRECT_MERCHANT_USER_ERR,
  DELETE_GENERIC_USER_BEGIN,
  DELETE_GENERIC_USER_SUCCESS,
  DELETE_GENERIC_USER_ERR,
  BLOCK_GENERIC_USER_BEGIN,
  BLOCK_GENERIC_USER_SUCCESS,
  BLOCK_GENERIC_USER_ERR,
  UNBLOCK_GENERIC_USER_BEGIN,
  UNBLOCK_GENERIC_USER_SUCCESS,
  UNBLOCK_GENERIC_USER_ERR,
  GET_GENERIC_USER_DETAILS_BEGIN,
  GET_GENERIC_USER_DETAILS_SUCCESS,
  GET_GENERIC_USER_DETAILS_ERR,
} = actions;

const initState = {
  loadingCreateDashboardAdmin: false,
  errorCreateDashboardAdmin: '',
  loadingUpdateDashboardAdmin: false,
  errorUpdateDashboardAdmin: '',
  dashboardAdmins: [],
  totalDashboardAdmins: 0,
  totalDashboardAdminsPages: 0,
  loadingCreateDashboardUser: false,
  errorCreateDashboardUser: '',
  loadingUpdateDashboardUser: false,
  errorUpdateDashboardUser: '',
  dashboardUsers: [...initialState],
  totalDashboardUsers: 0,
  totalDashboardUsersPages: 0,
  loadingListDashboardAdmins: false,
  errorListDashboardAdmins: '',
  loadingListDashboardUsers: false,
  errorListDashboardUsers: '',
  loadingUpdateAgentEntity: false,
  errorUpdateAgentEntity: '',
  agentEntities: [],
  totalAgentEntities: 0,
  totalAgentEntitiesPages: 0,
  loadingCreateAgentEntity: false,
  errorCreateAgentEntity: '',
  loadingUpdateAgentUser: false,
  errorUpdateAgentUser: '',
  agentUsers: [],
  totalAgentUsers: 0,
  totalAgentUsersPages: 0,
  loadingCreateAgentUser: false,
  errorCreateAgentUser: '',
  loadingListAgentEntities: false,
  errorListAgentEntities: '',
  loadingListAgentUsers: false,
  errorListAgentUsers: '',
  loadingUpdateDirectDelegate: false,
  errorUpdateDirectDelegate: '',
  directDelegates: [],
  totalDirectDelegates: 0,
  totalDirectDelegatesPages: 0,
  loadingListDirectDelegates: false,
  errorListDirectDelegates: '',
  loadingCreateDirectDelegate: false,
  errorCreateDirectDelegate: '',
  loadingUpdateDirectMerchant: false,
  errorUpdateDirectMerchant: '',
  directMerchants: [],
  totalDirectMerchants: 0,
  totalDirectMerchantsPages: 0,
  loadingListDirectMerchants: false,
  errorListDirectMerchants: '',
  loadingCreateDirectMerchant: false,
  errorCreateDirectMerchant: '',
  loadingUpdateDirectMerchantUser: false,
  errorUpdateDirectMerchantUser: '',
  directMerchantUsers: [],
  totalDirectMerchantUsers: 0,
  totalDirectMerchantUsersPages: 0,
  loadingCreateDirectMerchantUser: false,
  errorCreateDirectMerchantUser: '',
  loadingListDirectMerchantUsers: false,
  errorListDirectMerchantUsers: '',
  loadingDeleteGenericUser: false,
  errorDeleteGenericUser: '',
  loadingBlockGenericUser: false,
  errorBlockGenericUser: null,
  loadingUnBlockGenericUser: false,
  errorUnBlockGenericUser: null,
  loadingGetGenericUserDetails: false,
  errorGetGenericUserDetails: null,
  genericUserDetails: {},
};

const userReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CREATE_DASHBOARD_ADMIN_BEGIN:
      return {
        ...state,
        loadingCreateDashboardAdmin: true,
        errorCreateDashboardAdmin: '',
      };
    case CREATE_DASHBOARD_ADMIN_SUCCESS:
      return {
        ...state,
        dashboardAdmins: [...state.dashboardAdmins, data],
        loadingCreateDashboardAdmin: false,
        errorCreateDashboardAdmin: '',
      };
    case CREATE_DASHBOARD_ADMIN_ERR:
      return {
        ...state,
        errorCreateDashboardAdmin: err,
        loadingCreateDashboardAdmin: false,
      };

    case CREATE_DASHBOARD_USER_BEGIN:
      return {
        ...state,
        loadingCreateDashboardUser: true,
        errorCreateDashboardUser: false,
      };
    case CREATE_DASHBOARD_USER_SUCCESS:
      return {
        ...state,
        dashboardUsers: [...state.dashboardUsers, data],
        loadingCreateDashboardUser: false,
        errorCreateDashboardUser: null,
      };
    case CREATE_DASHBOARD_USER_ERR:
      return {
        ...state,
        errorCreateDashboardUser: err,
        loadingCreateDashboardUser: false,
      };

    case CREATE_AGENT_ENTITY_BEGIN:
      console.log('errorCreateAgentEntity in Begin: ', state.errorCreateAgentEntity);
      return {
        ...state,
        loadingCreateAgentEntity: true,
        errorCreateAgentEntity: '',
      };
    case CREATE_AGENT_ENTITY_SUCCESS:
      console.log('errorCreateAgentEntity in success: ', state.errorCreateAgentEntity);

      return {
        ...state,
        agentEntities: [...state.agentEntities, data],
        loadingCreateAgentEntity: false,
        errorCreateAgentEntity: '',
      };
    case CREATE_AGENT_ENTITY_ERR:
      console.log('Erooorrrr CREATE_AGENT_ENTITY_ERR', state.errorCreateAgentEntity);
      return {
        ...state,
        errorCreateAgentEntity: err,
        loadingCreateAgentEntity: false,
      };

    case CREATE_AGENT_USER_BEGIN:
      return {
        ...state,
        loadingCreateAgentUser: true,
        errorCreateAgentUser: null,
      };
    case CREATE_AGENT_USER_SUCCESS:
      return {
        ...state,
        agentUsers: [...state.agentUsers, data],
        loadingCreateAgentUser: false,
        errorCreateAgentUser: null,
      };
    case CREATE_AGENT_USER_ERR:
      return {
        ...state,
        errorCreateAgentUser: err,
        loadingCreateAgentUser: false,
      };
    case CREATE_DIRECT_DELEGATE_BEGIN:
      return {
        ...state,
        loadingCreateDirectDelegate: true,
        errorCreateDirectDelegate: null,
      };
    case CREATE_DIRECT_DELEGATE_SUCCESS:
      return {
        ...state,
        directDelegates: [...state.directDelegates, data],
        loadingCreateDirectDelegate: false,
        errorCreateDirectDelegate: null,
      };
    case CREATE_DIRECT_DELEGATE_ERR:
      return {
        ...state,
        errorCreateDirectDelegate: err,
        loadingCreateDirectDelegate: false,
      };
    case CREATE_DIRECT_MERCHANT_BEGIN:
      return {
        ...state,
        loadingCreateDirectMerchant: true,
        errorCreateDirectMerchant: null,
      };
    case CREATE_DIRECT_MERCHANT_SUCCESS:
      return {
        ...state,
        directMerchants: [...state.directMerchants, data],
        loadingCreateDirectMerchant: false,
        errorCreateDirectMerchant: null,
      };
    case CREATE_DIRECT_MERCHANT_ERR:
      return {
        ...state,
        errorCreateDirectMerchant: err,
        loadingCreateDirectMerchant: false,
      };
    case CREATE_DIRECT_MERCHANT_USER_BEGIN:
      return {
        ...state,
        loadingCreateDirectMerchantUser: true,
      };
    case CREATE_DIRECT_MERCHANT_USER_SUCCESS:
      return {
        ...state,
        directMerchantUsers: [...state.directMerchantUsers, data],
        loadingCreateDirectMerchantUser: false,
        errorCreateDirectMerchantUser: null,
      };
    case CREATE_DIRECT_MERCHANT_USER_ERR:
      return {
        ...state,
        errorCreateDirectMerchantUser: err,
        loadingCreateDirectMerchantUser: false,
      };

    case LIST_DASHBOARD_ADMINS_BEGIN:
      return {
        ...state,
        loadingListDashboardAdmins: true,
        errorListDashboardAdmins: false,
        totalDashboardAdmins: 0,
        totalDashboardAdminsPages: 0,
      };
    case LIST_DASHBOARD_ADMINS_SUCCESS:
      return {
        ...state,
        dashboardAdmins: [...data.results],
        loadingListDashboardAdmins: false,
        errorListDashboardAdmins: null,
        totalDashboardAdmins: data.total_count,
        totalDashboardAdminsPages: data.total_pages,
      };
    case LIST_DASHBOARD_ADMINS_ERR:
      return {
        ...state,
        errorListDashboardAdmins: err,
        loadingListDashboardAdmins: false,
        totalDashboardAdmins: 0,
        totalDashboardAdminsPages: 0,
      };
    case LIST_DASHBOARD_USERS_BEGIN:
      return {
        ...state,
        dashboardUsers: [],
        loadingListDashboardUsers: true,
        errorListDashboardUsers: false,
        totalDashboardUsers: 0,
        totalDashboardUsersPages: 0,
      };
    case LIST_DASHBOARD_USERS_SUCCESS:
      return {
        ...state,
        dashboardUsers: data.results,
        loadingListDashboardUsers: false,
        errorListDashboardUsers: null,
        totalDashboardUsers: data.total_count,
        totalDashboardUsersPages: data.total_pages,
      };
    case LIST_DASHBOARD_USERS_ERR:
      return {
        ...state,
        errorListDashboardUsers: err,
        loadingListDashboardUsers: false,
        totalDashboardUsers: 0,
        totalDashboardUsersPages: 0,
      };
    case LIST_AGENT_ENTITIES_BEGIN:
      return {
        ...state,
        agentEntities: [],
        loadingListAgentEntities: true,
        errorListAgentEntities: null,
        totalAgentEntities: 0,
        totalAgentEntitiesPages: 0,
      };
    case LIST_AGENT_ENTITIES_SUCCESS:
      return {
        ...state,
        agentEntities: [...data.results],
        loadingListAgentEntities: false,
        errorListAgentEntities: null,
        totalAgentEntities: data.total_count,
        totalAgentEntitiesPages: data.total_pages,
      };
    case LIST_AGENT_ENTITIES_ERR:
      return {
        ...state,
        errorListAgentEntities: err,
        loadingListAgentEntities: false,
        totalAgentEntities: 0,
        totalAgentEntitiesPages: 0,
      };
    case LIST_AGENT_USERS_BEGIN:
      return {
        ...state,
        agentUsers: [],
        loadingListAgentUsers: true,
        errorListAgentUsers: false,
        totalAgentUsers: 0,
        totalAgentUsersPages: 0,
      };
    case LIST_AGENT_USERS_SUCCESS:
      return {
        ...state,
        agentUsers: [...data.results],
        loadingListAgentUsers: false,
        errorListAgentUsers: null,
        totalAgentUsers: data.total_count,
        totalAgentUsersPages: data.total_pages,
      };
    case LIST_AGENT_USERS_ERR:
      return {
        ...state,
        errorListAgentUsers: err,
        loadingListAgentUsers: false,
        totalAgentUsers: 0,
        totalAgentUsersPages: 0,
      };

    case LIST_DIRECT_DELEGATES_BEGIN:
      return {
        ...state,
        directDelegates: [],
        loadingListDirectDelegates: true,
        errorListDirectDelegates: false,
        totalDirectDelegates: 0,
        totalDirectDelegatesPages: 0,
      };
    case LIST_DIRECT_DELEGATES_SUCCESS:
      return {
        ...state,
        directDelegates: [...data.results],
        loadingListDirectDelegates: false,
        errorListDirectDelegates: null,
        totalDirectDelegates: data.total_count,
        totalDirectDelegatesPages: data.total_pages,
      };
    case LIST_DIRECT_DELEGATES_ERR:
      return {
        ...state,
        errorListDirectDelegates: err,
        loadingListDirectDelegates: false,
        totalDirectDelegates: 0,
        totalDirectDelegatesPages: 0,
      };
    case LIST_DIRECT_MERCHANTS_BEGIN:
      return {
        ...state,
        directMerchants: [],
        loadingListDirectMerchants: true,
        errorListDirectMerchants: false,
        totalDirectMerchants: 0,
        totalDirectMerchantsPages: 0,
      };
    case LIST_DIRECT_MERCHANTS_SUCCESS:
      return {
        ...state,
        directMerchants: [...data.results],
        loadingListDirectMerchants: false,
        errorListDirectMerchants: null,
        totalDirectMerchants: data.total_count,
        totalDirectMerchantsPages: data.total_pages,
      };
    case LIST_DIRECT_MERCHANTS_ERR:
      return {
        ...state,
        errorListDirectMerchants: err,
        loadingListDirectMerchants: false,
        totalDirectMerchants: 0,
        totalDirectMerchantsPages: 0,
      };
    case LIST_DIRECT_MERCHANT_USERS_BEGIN:
      return {
        ...state,
        directMerchantUsers: [],
        loadingListDirectMerchantUsers: true,
        errorListDirectMerchantUsers: false,
        totalDirectMerchantUsers: 0,
        totalDirectMerchantUsersPages: 0,
      };
    case LIST_DIRECT_MERCHANT_USERS_SUCCESS:
      return {
        ...state,
        directMerchantUsers: [...data.results],
        loadingListDirectMerchantUsers: false,
        errorListDirectMerchantUsers: null,
        totalDirectMerchantUsers: data.total_count,
        totalDirectMerchantUsersPages: data.total_pages,
      };
    case LIST_DIRECT_MERCHANT_USERS_ERR:
      return {
        ...state,
        errorListDirectMerchantUsers: err,
        loadingListDirectMerchantUsers: false,
        totalDirectMerchantUsers: 0,
        totalDirectMerchantUsersPages: 0,
      };

    case UPDATE_DASHBOARD_ADMIN_BEGIN:
      return {
        ...state,
        loadingUpdateDashboardAdmin: true,
        errorUpdateDashboardAdmin: false,
      };
    case UPDATE_DASHBOARD_ADMIN_SUCCESS:
      const updatedAdmins = state.dashboardAdmins.map((admin) =>
        admin.account_id === data.account_id ? { ...admin, ...data } : admin,
      );
      return {
        ...state,
        dashboardAdmins: updatedAdmins,
        loadingUpdateDashboardAdmin: false,
        errorUpdateDashboardAdmin: null,
      };
    case UPDATE_DASHBOARD_ADMIN_ERR:
      return {
        ...state,
        errorUpdateDashboardAdmin: err,
        loadingUpdateDashboardAdmin: false,
      };
    case UPDATE_DASHBOARD_USER_BEGIN:
      return {
        ...state,
        loadingUpdateDashboardUser: true,
        errorUpdateDashboardUser: false,
      };
    case UPDATE_DASHBOARD_USER_SUCCESS:
      const updatedDashboardUsers = state.dashboardUsers.map((admin) =>
        admin.account_id === data.account_id ? { ...admin, ...data } : admin,
      );
      return {
        ...state,
        dashboardUsers: updatedDashboardUsers,
        loadingUpdateDashboardUser: false,
        errorUpdateDashboardUser: null,
      };
    case UPDATE_DASHBOARD_USER_ERR:
      return {
        ...state,
        errorUpdateDashboardUser: err,
        loadingUpdateDashboardUser: false,
      };
    case UPDATE_AGENT_ENTITY_BEGIN:
      return {
        ...state,
        loadingUpdateAgentEntity: true,
        errorUpdateAgentEntity: false,
      };
    case UPDATE_AGENT_ENTITY_SUCCESS:
      const updatedAgentEntities = state.agentEntities.map((admin) =>
        admin.account_id === data.account_id ? { ...admin, ...data } : admin,
      );
      return {
        ...state,
        agentEntities: updatedAgentEntities,
        loadingUpdateAgentEntity: false,
        errorUpdateAgentEntity: null,
      };
    case UPDATE_AGENT_ENTITY_ERR:
      return {
        ...state,
        errorUpdateAgentEntity: err,
        loadingUpdateAgentEntity: false,
      };
    case UPDATE_AGENT_USER_BEGIN:
      return {
        ...state,
        loadingUpdateAgentUser: true,
        errorUpdateAgentUser: false,
      };
    case UPDATE_AGENT_USER_SUCCESS:
      const updatedAgentUsers = state.agentUsers.map((admin) =>
        admin.account_id === data.account_id ? { ...admin, ...data } : admin,
      );
      return {
        ...state,
        agentUsers: updatedAgentUsers,
        loadingUpdateAgentUser: false,
        errorUpdateAgentUser: null,
      };
    case UPDATE_AGENT_USER_ERR:
      return {
        ...state,
        errorUpdateAgentUser: err,
        loadingUpdateAgentUser: false,
      };
    case UPDATE_DIRECT_DELEGATE_BEGIN:
      return {
        ...state,
        loadingUpdateDirectDelegate: true,
        errorUpdateDirectDelegate: false,
      };
    case UPDATE_DIRECT_DELEGATE_SUCCESS:
      const updatedDirectDelegate = state.directDelegates.map((admin) =>
        admin.account_id === data.account_id ? { ...admin, ...data } : admin,
      );
      return {
        ...state,
        directDelegates: updatedDirectDelegate,
        loadingUpdateDirectDelegate: false,
        errorUpdateDirectDelegate: null,
      };
    case UPDATE_DIRECT_DELEGATE_ERR:
      return {
        ...state,
        errorUpdateDirectDelegate: err,
        loadingUpdateDirectDelegate: false,
      };
    case UPDATE_DIRECT_MERCHANT_BEGIN:
      return {
        ...state,
        loadingUpdateDirectMerchant: true,
        errorUpdateDirectMerchant: false,
      };
    case UPDATE_DIRECT_MERCHANT_SUCCESS:
      const updatedDirectMerchants = state.directMerchants.map((admin) =>
        admin.account_id === data.account_id ? { ...admin, ...data } : admin,
      );
      return {
        ...state,
        directMerchants: updatedDirectMerchants,
        loadingUpdateDirectMerchant: false,
        errorUpdateDirectMerchant: null,
      };
    case UPDATE_DIRECT_MERCHANT_ERR:
      return {
        ...state,
        errorUpdateDirectMerchant: err,
        loadingUpdateDirectMerchant: false,
      };
    case UPDATE_DIRECT_MERCHANT_USER_BEGIN:
      return {
        ...state,
        loadingUpdateDirectMerchantUser: true,
        errorUpdateDirectMerchantUser: null,
      };
    case UPDATE_DIRECT_MERCHANT_USER_SUCCESS:
      const updatedDirectMerchantUsers = state.directMerchantUsers.map((admin) =>
        admin.account_id === data.account_id ? { ...admin, ...data } : admin,
      );

      return {
        ...state,
        directMerchantUsers: updatedDirectMerchantUsers,
        loadingUpdateDirectMerchantUser: false,
        errorUpdateDirectMerchantUser: null,
      };
    case UPDATE_DIRECT_MERCHANT_USER_ERR:
      return {
        ...state,
        errorUpdateDirectMerchantUser: err,
        loadingUpdateDirectMerchantUser: false,
      };

    case DELETE_GENERIC_USER_BEGIN:
      return {
        ...state,
        loadingDeleteGenericUser: true,
        errorDeleteGenericUser: null,
      };
    case DELETE_GENERIC_USER_SUCCESS:
      return {
        ...state,
        loadingDeleteGenericUser: false,
        errorDeleteGenericUser: null,
      };
    case DELETE_GENERIC_USER_ERR:
      return {
        ...state,
        errorDeleteGenericUser: err,
        loadingDeleteGenericUser: false,
      };

    case BLOCK_GENERIC_USER_BEGIN:
      return {
        ...state,
        loadingBlockGenericUser: true,
        errorBlockGenericUser: null,
      };
    case BLOCK_GENERIC_USER_SUCCESS:
      return {
        ...state,
        loadingBlockGenericUser: false,
        errorBlockGenericUser: null,
      };
    case BLOCK_GENERIC_USER_ERR:
      return {
        ...state,
        errorBlockGenericUser: err,
        loadingBlockGenericUser: false,
      };
    case UNBLOCK_GENERIC_USER_BEGIN:
      return {
        ...state,
        loadingUnBlockGenericUser: true,
        errorUnBlockGenericUser: null,
      };
    case UNBLOCK_GENERIC_USER_SUCCESS:
      return {
        ...state,
        loadingUnBlockGenericUser: false,
        errorUnBlockGenericUser: null,
      };
    case UNBLOCK_GENERIC_USER_ERR:
      return {
        ...state,
        loadingUnBlockGenericUser: false,
        errorUnBlockGenericUser: err,
      };
    case GET_GENERIC_USER_DETAILS_BEGIN:
      return {
        ...state,
        genericUserDetails: {},
        loadingGetGenericUserDetails: true,
        errorGetGenericUserDetails: null,
      };
    case GET_GENERIC_USER_DETAILS_SUCCESS:
      return {
        ...state,
        genericUserDetails: data,
        loadingGetGenericUserDetails: false,
        errorGetGenericUserDetails: null,
      };
    case GET_GENERIC_USER_DETAILS_ERR:
      return {
        ...state,
        genericUserDetails: {},
        loadingGetGenericUserDetails: false,
        errorGetGenericUserDetails: err,
      };

    default:
      return state;
  }
};

const userGroupReducer = (state = initialStateGroup) => {
  return state;
};

export { userReducer, userGroupReducer };
