const actions = {
  // STOCK ACTIONS
  LIST_PRODUCT_STOCK_BEGIN: 'LIST_PRODUCT_STOCK_BEGIN',
  LIST_PRODUCT_STOCK_SUCCESS: 'LIST_PRODUCT_STOCK_SUCCESS',
  LIST_PRODUCT_STOCK_ERR: 'LIST_PRODUCT_STOCK_ERR',

  GET_PRODUCT_STOCK_DETAILS_BEGIN: 'GET_PRODUCT_STOCK_DETAILS_BEGIN',
  GET_PRODUCT_STOCK_DETAILS_SUCCESS: 'GET_PRODUCT_STOCK_DETAILS_SUCCESS',
  GET_PRODUCT_STOCK_DETAILS_ERR: 'GET_PRODUCT_STOCK_DETAILS_ERR',

  GET_STOCK_SUMMARY_REPORT_BEGIN: 'GET_STOCK_SUMMARY_REPORT_BEGIN',
  GET_STOCK_SUMMARY_REPORT_SUCCESS: 'GET_STOCK_SUMMARY_REPORT_SUCCESS',
  GET_STOCK_SUMMARY_REPORT_ERR: 'GET_STOCK_SUMMARY_REPORT_ERR',

  ADD_PRODUCT_STOCK_BEGIN: 'ADD_PRODUCT_STOCK_BEGIN',
  ADD_PRODUCT_STOCK_SUCCESS: 'ADD_PRODUCT_STOCK_SUCCESS',
  ADD_PRODUCT_STOCK_ERR: 'ADD_PRODUCT_STOCK_ERR',

  REMOVE_PRODUCT_STOCK_BEGIN: 'REMOVE_PRODUCT_STOCK_BEGIN',
  REMOVE_PRODUCT_STOCK_SUCCESS: 'REMOVE_PRODUCT_STOCK_SUCCESS',
  REMOVE_PRODUCT_STOCK_ERR: 'REMOVE_PRODUCT_STOCK_ERR',

  GENERATE_KARZ_STOCK_BEGIN: 'GENERATE_KARZ_STOCK_BEGIN',
  GENERATE_KARZ_STOCK_SUCCESS: 'GENERATE_KARZ_STOCK_SUCCESS',
  GENERATE_KARZ_STOCK_ERR: 'GENERATE_KARZ_STOCK_ERR',

  addKARZStockBegin: () => {
    return {
      type: actions.GENERATE_KARZ_STOCK_BEGIN,
    };
  },

  addKARZStockSuccess: (data) => {
    return {
      type: actions.GENERATE_KARZ_STOCK_SUCCESS,
      data,
    };
  },

  addKARZStockErr: (err) => {
    return {
      type: actions.GENERATE_KARZ_STOCK_ERR,
      err,
    };
  },

  listProductStockBegin: () => {
    return {
      type: actions.LIST_PRODUCT_STOCK_BEGIN,
    };
  },

  listProductStockSuccess: (data) => {
    return {
      type: actions.LIST_PRODUCT_STOCK_SUCCESS,
      data,
    };
  },

  listProductStockErr: (err) => {
    return {
      type: actions.LIST_PRODUCT_STOCK_ERR,
      err,
    };
  },

  getStockSummaryReportBegin: () => {
    return {
      type: actions.GET_STOCK_SUMMARY_REPORT_BEGIN,
    };
  },

  getStockSummaryReportSuccess: (data) => {
    return {
      type: actions.GET_STOCK_SUMMARY_REPORT_SUCCESS,
      data,
    };
  },

  getStockSummaryReportErr: (err) => {
    return {
      type: actions.GET_STOCK_SUMMARY_REPORT_ERR,
      err,
    };
  },

  getProductStockDetailsBegin: () => {
    return {
      type: actions.GET_PRODUCT_STOCK_DETAILS_BEGIN,
    };
  },

  getProductStockDetailsSuccess: (data) => {
    return {
      type: actions.GET_PRODUCT_STOCK_DETAILS_SUCCESS,
      data,
    };
  },

  getProductStockDetailsErr: (err) => {
    return {
      type: actions.GET_PRODUCT_STOCK_DETAILS_ERR,
      err,
    };
  },

  addProductStockBegin: () => {
    return {
      type: actions.ADD_PRODUCT_STOCK_BEGIN,
    };
  },

  addProductStockSuccess: (data) => {
    return {
      type: actions.ADD_PRODUCT_STOCK_SUCCESS,
      data,
    };
  },

  addProductStockErr: (err) => {
    return {
      type: actions.ADD_PRODUCT_STOCK_ERR,
      err,
    };
  },

  removeProductStockBegin: () => {
    return {
      type: actions.REMOVE_PRODUCT_STOCK_BEGIN,
    };
  },

  removeProductStockSuccess: (data) => {
    return {
      type: actions.REMOVE_PRODUCT_STOCK_SUCCESS,
      data,
    };
  },

  removeProductStockErr: (err) => {
    return {
      type: actions.REMOVE_PRODUCT_STOCK_ERR,
      err,
    };
  },
};

export default actions;
