const actions = {
  // BRANDS ACTIONS
  CREATE_BRAND_BEGIN: 'CREATE_BRAND_BEGIN',
  CREATE_BRAND_SUCCESS: 'CREATE_BRAND_SUCCESS',
  CREATE_BRAND_ERR: 'CREATE_BRAND_ERR',

  LIST_BRANDS_BEGIN: 'LIST_BRANDS_BEGIN',
  LIST_BRANDS_SUCCESS: 'LIST_BRANDS_SUCCESS',
  LIST_BRANDS_ERR: 'LIST_BRANDS_ERR',

  GET_BRAND_DETAILS_BEGIN: 'GET_BRAND_DETAILS_BEGIN',
  GET_BRAND_DETAILS_SUCCESS: 'GET_BRAND_DETAILS_SUCCESS',
  GET_BRAND_DETAILS_ERR: 'GET_BRAND_DETAILS_ERR',

  DELETE_BRAND_BEGIN: 'DELETE_BRAND_BEGIN',
  DELETE_BRAND_SUCCESS: 'DELETE_BRAND_SUCCESS',
  DELETE_BRAND_ERR: 'DELETE_BRAND_ERR',

  UPDATE_BRAND_BEGIN: 'UPDATE_BRAND_BEGIN',
  UPDATE_BRAND_SUCCESS: 'UPDATE_BRAND_SUCCESS',
  UPDATE_BRAND_ERR: 'UPDATE_BRAND_ERR',

  ENABLE_BRAND_BEGIN: 'ENABLE_BRAND_BEGIN',
  ENABLE_BRAND_SUCCESS: 'ENABLE_BRAND_SUCCESS',
  ENABLE_BRAND_ERR: 'ENABLE_BRAND_ERR',

  DISABLE_BRAND_BEGIN: 'DISABLE_BRAND_BEGIN',
  DISABLE_BRAND_SUCCESS: 'DISABLE_BRAND_SUCCESS',
  DISABLE_BRAND_ERR: 'DISABLE_BRAND_ERR',

  // BRAND CATEGORIES ACTIONS
  CREATE_BRAND_CATEGORY_BEGIN: 'CREATE_BRAND_CATEGORY_BEGIN',
  CREATE_BRAND_CATEGORY_SUCCESS: 'CREATE_BRAND_CATEGORY_SUCCESS',
  CREATE_BRAND_CATEGORY_ERR: 'CREATE_BRAND_CATEGORY_ERR',

  LIST_BRAND_CATEGORIES_BEGIN: 'LIST_BRAND_CATEGORIES_BEGIN',
  LIST_BRAND_CATEGORIES_SUCCESS: 'LIST_BRAND_CATEGORIES_SUCCESS',
  LIST_BRAND_CATEGORIES_ERR: 'LIST_BRAND_CATEGORIES_ERR',

  DELETE_BRAND_CATEGORY_BEGIN: 'DELETE_BRAND_CATEGORY_BEGIN',
  DELETE_BRAND_CATEGORY_SUCCESS: 'DELETE_BRAND_CATEGORY_SUCCESS',
  DELETE_BRAND_CATEGORY_ERR: 'DELETE_BRAND_CATEGORY_ERR',

  UPDATE_BRAND_CATEGORY_BEGIN: 'UPDATE_BRAND_CATEGORY_BEGIN',
  UPDATE_BRAND_CATEGORY_SUCCESS: 'UPDATE_BRAND_CATEGORY_SUCCESS',
  UPDATE_BRAND_CATEGORY_ERR: 'UPDATE_BRAND_CATEGORY_ERR',

  ENABLE_BRAND_CATEGORY_BEGIN: 'ENABLE_BRAND_CATEGORY_BEGIN',
  ENABLE_BRAND_CATEGORY_SUCCESS: 'ENABLE_BRAND_CATEGORY_SUCCESS',
  ENABLE_BRAND_CATEGORY_ERR: 'ENABLE_BRAND_CATEGORY_ERR',

  DISABLE_BRAND_CATEGORY_BEGIN: 'DISABLE_BRAND_CATEGORY_BEGIN',
  DISABLE_BRAND_CATEGORY_SUCCESS: 'DISABLE_BRAND_CATEGORY_SUCCESS',
  DISABLE_BRAND_CATEGORY_ERR: 'DISABLE_BRAND_CATEGORY_ERR',

  createBrandBegin: () => {
    return {
      type: actions.CREATE_BRAND_BEGIN,
    };
  },

  createBrandSuccess: (data) => {
    return {
      type: actions.CREATE_BRAND_SUCCESS,
      data,
    };
  },

  createBrandErr: (err) => {
    return {
      type: actions.CREATE_BRAND_ERR,
      err,
    };
  },

  listBrandsBegin: () => {
    return {
      type: actions.LIST_BRANDS_BEGIN,
    };
  },

  listBrandsSuccess: (data) => {
    return {
      type: actions.LIST_BRANDS_SUCCESS,
      data,
    };
  },

  listBrandsErr: (err) => {
    return {
      type: actions.LIST_BRANDS_ERR,
      err,
    };
  },
  getBrandDetailsBegin: () => {
    return {
      type: actions.GET_BRAND_DETAILS_BEGIN,
    };
  },

  getBrandDetailsSuccess: (data) => {
    return {
      type: actions.GET_BRAND_DETAILS_SUCCESS,
      data,
    };
  },

  getBrandDetailsErr: (err) => {
    return {
      type: actions.GET_BRAND_DETAILS_ERR,
      err,
    };
  },
  updateBrandBegin: () => {
    return {
      type: actions.UPDATE_BRAND_BEGIN,
    };
  },

  updateBrandSuccess: (data) => {
    return {
      type: actions.UPDATE_BRAND_SUCCESS,
      data,
    };
  },

  updateBrandErr: (err) => {
    return {
      type: actions.UPDATE_BRAND_ERR,
      err,
    };
  },

  deleteBrandBegin: () => {
    return {
      type: actions.DELETE_BRAND_BEGIN,
    };
  },

  deleteBrandSuccess: (data) => {
    return {
      type: actions.DELETE_BRAND_SUCCESS,
      data,
    };
  },

  deleteBrandErr: (err) => {
    return {
      type: actions.DELETE_BRAND_ERR,
      err,
    };
  },

  enableBrandBegin: () => {
    return {
      type: actions.ENABLE_BRAND_BEGIN,
    };
  },

  enableBrandSuccess: (data) => {
    return {
      type: actions.ENABLE_BRAND_SUCCESS,
      data,
    };
  },

  enableBrandErr: (err) => {
    return {
      type: actions.ENABLE_BRAND_ERR,
      err,
    };
  },

  disableBrandBegin: () => {
    return {
      type: actions.DISABLE_BRAND_BEGIN,
    };
  },

  disableBrandSuccess: (data) => {
    return {
      type: actions.DISABLE_BRAND_SUCCESS,
      data,
    };
  },

  disableBrandErr: (err) => {
    return {
      type: actions.DISABLE_BRAND_ERR,
      err,
    };
  },

  // Brand categories Action creators
  createBrandCategoryBegin: () => {
    return {
      type: actions.CREATE_BRAND_CATEGORY_BEGIN,
    };
  },

  createBrandCategorySuccess: (data) => {
    return {
      type: actions.CREATE_BRAND_CATEGORY_SUCCESS,
      data,
    };
  },

  createBrandCategoryErr: (err) => {
    return {
      type: actions.CREATE_BRAND_CATEGORY_ERR,
      err,
    };
  },

  listBrandCategoriesBegin: () => {
    return {
      type: actions.LIST_BRAND_CATEGORIES_BEGIN,
    };
  },

  listBrandCategoriesSuccess: (data) => {
    return {
      type: actions.LIST_BRAND_CATEGORIES_SUCCESS,
      data,
    };
  },

  listBrandCategoriesErr: (err) => {
    return {
      type: actions.LIST_BRAND_CATEGORIES_ERR,
      err,
    };
  },
  updateBrandCategoryBegin: () => {
    return {
      type: actions.UPDATE_BRAND_CATEGORY_BEGIN,
    };
  },

  updateBrandCategorySuccess: (data) => {
    return {
      type: actions.UPDATE_BRAND_CATEGORY_SUCCESS,
      data,
    };
  },

  updateBrandCategoryErr: (err) => {
    return {
      type: actions.UPDATE_BRAND_CATEGORY_ERR,
      err,
    };
  },

  deleteBrandCategoryBegin: () => {
    return {
      type: actions.DELETE_BRAND_CATEGORY_BEGIN,
    };
  },

  deleteBrandCategorySuccess: (data) => {
    return {
      type: actions.DELETE_BRAND_CATEGORY_SUCCESS,
      data,
    };
  },

  deleteBrandCategoryErr: (err) => {
    return {
      type: actions.DELETE_BRAND_CATEGORY_ERR,
      err,
    };
  },

  enableBrandCategoryBegin: () => {
    return {
      type: actions.ENABLE_BRAND_CATEGORY_BEGIN,
    };
  },

  enableBrandCategorySuccess: (data) => {
    return {
      type: actions.ENABLE_BRAND_CATEGORY_SUCCESS,
      data,
    };
  },

  enableBrandCategoryErr: (err) => {
    return {
      type: actions.ENABLE_BRAND_CATEGORY_ERR,
      err,
    };
  },

  disableBrandCategoryBegin: () => {
    return {
      type: actions.DISABLE_BRAND_CATEGORY_BEGIN,
    };
  },

  disableBrandCategorySuccess: (data) => {
    return {
      type: actions.DISABLE_BRAND_CATEGORY_SUCCESS,
      data,
    };
  },

  disableBrandCategoryErr: (err) => {
    return {
      type: actions.DISABLE_BRAND_CATEGORY_ERR,
      err,
    };
  },
};

export default actions;
