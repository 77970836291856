import actions from './actions';

const {
  UPLOAD_DEVICES_BEGIN,
  UPLOAD_DEVICES_SUCCESS,
  UPLOAD_DEVICES_ERR,
  LIST_DEVICES_BEGIN,
  LIST_DEVICES_SUCCESS,
  LIST_DEVICES_ERR,
  GET_DEVICE_DETAILS_BEGIN,
  GET_DEVICE_DETAILS_SUCCESS,
  GET_DEVICE_DETAILS_ERR,
  ASSIGN_DEVICE_BEGIN,
  ASSIGN_DEVICE_SUCCESS,
  ASSIGN_DEVICE_ERR,
  DELETE_DEVICE_BEGIN,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_ERR,
  DELETE_DEVICES_BY_ADD_KEY_BEGIN,
  DELETE_DEVICES_BY_ADD_KEY_SUCCESS,
  DELETE_DEVICES_BY_ADD_KEY_ERR,
  LIST_DEVICE_CHANGES_BEGIN,
  LIST_DEVICE_CHANGES_SUCCESS,
  LIST_DEVICE_CHANGES_ERR,
  LIST_DEVICE_PROVIDERS_BEGIN,
  LIST_DEVICE_PROVIDERS_SUCCESS,
  LIST_DEVICE_PROVIDERS_ERR,
} = actions;

const initState = {
  loadingUploadDevices: false,
  errorUploadDevices: '',
  devices: [],
  loadingListDevices: false,
  errorListDevices: '',
  totalDevicesCount: 0,
  totalPages: 0,

  loadingAssignDevice: false,
  errorAssignDevice: '',
  loadingGetDeviceDetails: false,
  errorGetDeviceDetails: '',
  deviceDetails: {},

  loadingEnableDeviceDetails: false,
  errorEnableDeviceDetails: '',
  loadingDisableDeviceDetails: false,
  errorDisableDeviceDetails: '',

  loadingDeleteDevice: false,
  errorDeleteDevice: '',

  loadingDeleteDevicesByAddKey: false,
  errorDeleteDevicesByAddKey: '',

  loadingListDeviceChanges: false,
  errorListDeviceChanges: '',
  deviceChanges: [],
  totalDevicesChangesCount: 0,
  totalDeviceChangesPages: 0,

  loadingListDeviceProviders: false,
  errorListDeviceProviders: '',
  deviceProviders: [],
  totalDevicesProvidersCount: 0,
  totalDeviceProvidersPages: 0,
};

const devicesReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPLOAD_DEVICES_BEGIN:
      return {
        ...state,
        loadingUploadDevices: true,
        errorUploadDevices: '',
      };
    case UPLOAD_DEVICES_SUCCESS:
      return {
        ...state,
        devices: [...state.devices, data],
        loadingUploadDevices: false,
        errorUploadDevices: '',
      };
    case UPLOAD_DEVICES_ERR:
      return {
        ...state,
        errorUploadDevices: err,
        loadingUploadDevices: false,
      };

    case LIST_DEVICES_BEGIN:
      return {
        ...state,
        loadingListDevices: true,
        errorListDevices: null,
        totalDevicesCount: 0,
        totalPages: 0,
      };
    case LIST_DEVICES_SUCCESS:
      return {
        ...state,
        devices: [...data.results],
        loadingListDevices: false,
        errorListDevices: null,
        totalDevicesCount: data.total_count,
        totalPages: data.total_pages,
      };
    case LIST_DEVICES_ERR:
      return {
        ...state,
        errorListDevices: err,
        loadingListDevices: false,
        totalDevicesCount: 0,
        totalPages: 0,
      };

    case GET_DEVICE_DETAILS_BEGIN:
      return {
        ...state,
        deviceDetails: {},
        loadingGetDeviceDetails: true,
        errorGetDeviceDetails: null,
      };
    case GET_DEVICE_DETAILS_SUCCESS:
      return {
        ...state,
        deviceDetails: data,
        loadingGetDeviceDetails: false,
        errorGetDeviceDetails: null,
      };
    case GET_DEVICE_DETAILS_ERR:
      return {
        ...state,
        loadingGetDeviceDetails: false,
        errorGetDeviceDetails: err,
        deviceDetails: {},
      };

    case ASSIGN_DEVICE_BEGIN:
      return {
        ...state,
        loadingAssignDevice: true,
        errorAssignDevice: null,
      };
    case ASSIGN_DEVICE_SUCCESS:
      // eslint-disable-next-line no-case-declarations

      return {
        ...state,
        loadingAssignDevice: false,
        errorAssignDevice: null,
      };
    case ASSIGN_DEVICE_ERR:
      return {
        ...state,
        errorAssignDevice: err,
        loadingAssignDevice: false,
      };

    case DELETE_DEVICE_BEGIN:
      return {
        ...state,
        loadingDeleteDevice: true,
        errorDeleteDevice: null,
      };
    case DELETE_DEVICE_SUCCESS:
      return {
        ...state,
        devices: [],
        loadingDeleteDevice: false,
        errorDeleteDevice: null,
      };
    case DELETE_DEVICE_ERR:
      return {
        ...state,
        errorDeleteDevice: err,
        loadingDeleteDevice: false,
      };

    case DELETE_DEVICES_BY_ADD_KEY_BEGIN:
      return {
        ...state,
        loadingDeleteDevicesByAddKey: true,
        errorDeleteDevicesByAddKey: '',
      };
    case DELETE_DEVICES_BY_ADD_KEY_SUCCESS:
      return {
        ...state,
        devices: [],
        loadingDeleteDevicesByAddKey: false,
        errorDeleteDevicesByAddKey: '',
      };
    case DELETE_DEVICES_BY_ADD_KEY_ERR:
      return {
        ...state,
        loadingDeleteDevicesByAddKey: false,
        errorDeleteDevicesByAddKey: err,
      };

    case LIST_DEVICE_CHANGES_BEGIN:
      return {
        ...state,
        loadingListDeviceChanges: true,
        errorListDeviceChanges: null,
        totalDevicesChangesCount: 0,
        totalDeviceChangesPages: 0,
      };
    case LIST_DEVICE_CHANGES_SUCCESS:
      return {
        ...state,
        deviceChanges: [...data.results],
        loadingListDeviceChanges: false,
        errorListDeviceChanges: null,
        totalDevicesChangesCount: data.total_count,
        totalDeviceChangesPages: data.total_pages,
      };
    case LIST_DEVICE_CHANGES_ERR:
      return {
        ...state,
        loadingListDeviceChanges: false,
        errorListDeviceChanges: err,
        totalDevicesChangesCount: 0,
        totalDeviceChangesPages: 0,
      };
    case LIST_DEVICE_PROVIDERS_BEGIN:
      return {
        ...state,
        loadingListDeviceProviders: true,
        errorListDeviceProviders: null,
        totalDevicesProvidersCount: 0,
        totalDeviceProvidersPages: 0,
      };
    case LIST_DEVICE_PROVIDERS_SUCCESS:
      return {
        ...state,
        deviceProviders: [...data],
        loadingListDeviceProviders: false,
        errorListDeviceProviders: null,
        totalDevicesProvidersCount: data.total_count,
        totalDeviceProvidersPages: data.total_pages,
      };
    case LIST_DEVICE_PROVIDERS_ERR:
      return {
        ...state,
        loadingListDeviceProviders: false,
        errorListDeviceProviders: err,
        totalDevicesProvidersCount: 0,
        totalDeviceProvidersPages: 0,
      };

    default:
      return state;
  }
};

export { devicesReducer };
