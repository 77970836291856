import actions from './actions';

const {
  CREATE_CATEGORY_BEGIN,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_ERR,
  LIST_CATEGORIES_BEGIN,
  LIST_CATEGORIES_SUCCESS,
  LIST_CATEGORIES_ERR,
  GET_CATEGORY_DETAILS_BEGIN,
  GET_CATEGORY_DETAILS_SUCCESS,
  GET_CATEGORY_DETAILS_ERR,
  UPDATE_CATEGORY_BEGIN,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERR,
  DELETE_CATEGORY_BEGIN,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERR,
  ENABLE_CATEGORY_BEGIN,
  ENABLE_CATEGORY_SUCCESS,
  ENABLE_CATEGORY_ERR,
  DISABLE_CATEGORY_BEGIN,
  DISABLE_CATEGORY_SUCCESS,
  DISABLE_CATEGORY_ERR,
} = actions;

const initState = {
  loadingCreateCategory: false,
  errorCreateCategory: '',
  categories: [],
  loadingListCategories: false,
  errorListCategories: '',
  loadingUpdateCategory: false,
  errorUpdateCategory: '',
  loadingGetCategoryDetails: false,
  errorGetCategoryDetails: '',
  categoryDetails: {},

  loadingEnableCategoryDetails: false,
  errorEnableCategoryDetails: '',
  loadingDisableCategoryDetails: false,
  errorDisableCategoryDetails: '',
};

const categoryReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CREATE_CATEGORY_BEGIN:
      return {
        ...state,
        loadingCreateCategory: true,
        errorCreateCategory: '',
      };
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, data],
        loadingCreateCategory: false,
        errorCreateCategory: '',
      };
    case CREATE_CATEGORY_ERR:
      return {
        ...state,
        errorCreateCategory: err,
        loadingCreateCategory: false,
      };

    case LIST_CATEGORIES_BEGIN:
      return {
        ...state,
        loadingListCategories: true,
        errorListCategories: null,
      };
    case LIST_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: [...data],
        loadingListCategories: false,
        errorListCategories: null,
      };
    case LIST_CATEGORIES_ERR:
      return {
        ...state,
        errorListCategories: err,
        loadingListCategories: false,
      };

    case GET_CATEGORY_DETAILS_BEGIN:
      return {
        ...state,
        categoryDetails: {},
        loadingGetCategoryDetails: true,
        errorGetCategoryDetails: null,
      };
    case GET_CATEGORY_DETAILS_SUCCESS:
      return {
        ...state,
        categoryDetails: data,
        loadingGetCategoryDetails: false,
        errorGetCategoryDetails: null,
      };
    case GET_CATEGORY_DETAILS_ERR:
      return {
        ...state,
        loadingGetCategoryDetails: false,
        errorGetCategoryDetails: err,
        categoryDetails: {},
      };

    case UPDATE_CATEGORY_BEGIN:
      return {
        ...state,
        loadingUpdateCategory: true,
        errorUpdateCategory: null,
      };
    case UPDATE_CATEGORY_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedList = state.categories.map((item) => (item.app_category_id === data.app_category_id ? data : item));

      return {
        ...state,
        categories: [...updatedList],
        loadingUpdateCategory: false,
        errorUpdateCategory: null,
      };
    case UPDATE_CATEGORY_ERR:
      return {
        ...state,
        errorUpdateCategory: err,
        loadingUpdateCategory: false,
      };

    case DELETE_CATEGORY_BEGIN:
      return {
        ...state,
        loadingDeleteCategory: true,
        errorDeleteCategory: null,
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loadingDeleteCategory: false,
        errorDeleteCategory: null,
      };
    case DELETE_CATEGORY_ERR:
      return {
        ...state,
        errorDeleteCategory: err,
        loadingDeleteCategory: false,
      };
    case ENABLE_CATEGORY_BEGIN:
      return {
        ...state,
        loadingEnableCategory: true,
        errorEnableCategory: null,
      };
    case ENABLE_CATEGORY_SUCCESS:
      return {
        ...state,
        loadingEnableCategory: false,
        errorEnableCategory: null,
      };
    case ENABLE_CATEGORY_ERR:
      return {
        ...state,
        errorEnableCategory: err,
        loadingEnableCategory: false,
      };
    case DISABLE_CATEGORY_BEGIN:
      return {
        ...state,
        loadingDisableCategory: true,
        errorDisableCategory: null,
      };
    case DISABLE_CATEGORY_SUCCESS:
      return {
        ...state,
        loadingDisableCategory: false,
        errorDisableCategory: null,
      };
    case DISABLE_CATEGORY_ERR:
      return {
        ...state,
        errorDisableCategory: err,
        loadingDisableCategory: false,
      };

    default:
      return state;
  }
};

export { categoryReducer };
