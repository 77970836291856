import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const AddSupplierForm = lazy(() => import('../../container/suppliers/AddSupplier'));
const EditSupplierForm = lazy(() => import('../../container/suppliers/EditSupplierForm'));
const SuppliersList = lazy(() => import('../../container/suppliers'));

function IconsRoute() {
  return (
    <Routes>
      <Route path="/" element={<SuppliersList />} />
      <Route path="/add-supplier" element={<AddSupplierForm />} />
      <Route path="/:id/edit" element={<EditSupplierForm />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default IconsRoute;
