import actions from './actions';
import staticData from '../../demoData/products.json';

const {
  SINGLE_PRODUCT_BEGIN,
  SINGLE_PRODUCT_SUCCESS,
  SINGLE_PRODUCT_ERR,

  FILTER_PRODUCT_BEGIN,
  FILTER_PRODUCT_SUCCESS,
  FILTER_PRODUCT_ERR,

  SORTING_PRODUCT_BEGIN,
  SORTING_PRODUCT_SUCCESS,
  SORTING_PRODUCT_ERR,

  // New ones
  CREATE_PRODUCT_BEGIN,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_ERR,
  LIST_PRODUCTS_BEGIN,
  LIST_PRODUCTS_SUCCESS,
  LIST_PRODUCTS_ERR,
  UPDATE_PRODUCT_BEGIN,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_ERR,
  DELETE_PRODUCT_BEGIN,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERR,
  LIST_PRODUCT_GROUP_SELLING_PRICES_BEGIN,
  LIST_PRODUCT_GROUP_SELLING_PRICES_SUCCESS,
  LIST_PRODUCT_GROUP_SELLING_PRICES_ERR,
  UPDATE_PRODUCT_SELLING_PRICES_BEGIN,
  UPDATE_PRODUCT_SELLING_PRICES_SUCCESS,
  UPDATE_PRODUCT_SELLING_PRICES_ERR,
  ENABLE_PRODUCT_BEGIN,
  ENABLE_PRODUCT_SUCCESS,
  ENABLE_PRODUCT_ERR,
  DISABLE_PRODUCT_BEGIN,
  DISABLE_PRODUCT_SUCCESS,
  DISABLE_PRODUCT_ERR,
  GET_PRODUCT_DETAILS_BEGIN,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_ERR,
  LIST_ALL_PRODUCTS_BEGIN,
  LIST_ALL_PRODUCTS_SUCCESS,
  LIST_ALL_PRODUCTS_ERR,
} = actions;

const initialStateFilter = {
  data: staticData,
  loading: false,
  error: null,
};

const initialState = {
  data: staticData,
  loading: false,
  error: null,
  // New state
  products: [],
  loadingCreateProduct: false,
  errorCreateProduct: '',
  loadingListProducts: false,
  errorListProducts: null,
  loadingDeleteProduct: false,
  errorDeleteProduct: null,
  loadingUpdateProduct: false,
  errorUpdateProduct: null,
  productGroupSellingPrices: [],
  loadingProductGroupSellingPrices: false,
  errorProductGroupSellingPrices: null,
  loadingUpdateProductSellingPrices: false,
  errorUpdateProductSellingPrices: null,

  loadingEnableProduct: false,
  errorEnableProduct: null,
  loadingDisableProduct: false,
  errorDisableProduct: null,
  productDetails: {},
  loadingGetProductDetailsProduct: false,
  errorGetProductDetailsProduct: null,

  allProducts: [],
  loadingListAllProducts: false,
  errorListAllProducts: null,
  allProductsTotalCount: 0,
  allProductsTotalPages: 0,
};

const productReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FILTER_PRODUCT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case FILTER_PRODUCT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case FILTER_PRODUCT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    case SORTING_PRODUCT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SORTING_PRODUCT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case SORTING_PRODUCT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };

    // New Reducer cases

    case CREATE_PRODUCT_BEGIN:
      return {
        ...state,
        loadingCreateProduct: true,
        errorCreateProduct: '',
      };
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...state.products, data],
        loadingCreateProduct: false,
        errorCreateProduct: '',
      };
    case CREATE_PRODUCT_ERR:
      return {
        ...state,
        errorCreateProduct: err,
        loadingCreateProduct: false,
      };
    case LIST_PRODUCTS_BEGIN:
      return {
        ...state,
        loadingListProducts: true,
        errorListProducts: '',
      };
    case LIST_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: [...data],
        loadingListProducts: false,
        errorListProducts: null,
      };
    case LIST_PRODUCTS_ERR:
      return {
        ...state,
        errorListProducts: err,
        loadingListProducts: false,
      };

    case UPDATE_PRODUCT_BEGIN:
      return {
        ...state,
        loadingUpdateProduct: true,
        errorUpdateProduct: null,
      };
    case UPDATE_PRODUCT_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedList = state.products.map((item) => (item.id === data.id ? data : item));

      return {
        ...state,
        products: [...updatedList],
        loadingUpdateProduct: false,
        errorUpdateProduct: null,
      };
    case UPDATE_PRODUCT_ERR:
      return {
        ...state,
        errorUpdateProduct: err,
        loadingUpdateProduct: false,
      };

    case DELETE_PRODUCT_BEGIN:
      return {
        ...state,
        loadingDeleteProduct: true,
        errorDeleteProduct: null,
      };
    case DELETE_PRODUCT_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { productId } = action.payload; // Assuming the action payload contains the ID of the deleted product

      return {
        ...state,
        products: state.products.filter((product) => product.id !== productId),
        loadingDeleteProduct: false,
        errorDeleteProduct: null,
      };
    case DELETE_PRODUCT_ERR:
      return {
        ...state,
        errorDeleteProduct: err,
        loadingDeleteProduct: false,
      };

    case ENABLE_PRODUCT_BEGIN:
      return {
        ...state,
        loadingEnableProduct: true,
        errorEnableProduct: null,
      };
    case ENABLE_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingEnableProduct: false,
        errorEnableProduct: null,
      };
    case ENABLE_PRODUCT_ERR:
      return {
        ...state,
        errorEnableProduct: err,
        loadingEnableProduct: false,
      };
    case DISABLE_PRODUCT_BEGIN:
      return {
        ...state,
        loadingDisableProduct: true,
        errorDisableProduct: null,
      };
    case DISABLE_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingDisableProduct: false,
        errorDisableProduct: null,
      };
    case DISABLE_PRODUCT_ERR:
      return {
        ...state,
        errorDisableProduct: err,
        loadingDisableProduct: false,
      };
    case GET_PRODUCT_DETAILS_BEGIN:
      return {
        ...state,
        productDetails: null,
        loadingGetProductDetailsProduct: true,
        errorGetProductDetailsProduct: null,
      };
    case GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetails: data,
        loadingGetProductDetailsProduct: false,
        errorGetProductDetailsProduct: null,
      };
    case GET_PRODUCT_DETAILS_ERR:
      return {
        ...state,
        productDetails: null,
        loadingGetProductDetailsProduct: false,
        errorGetProductDetailsProduct: err,
      };

    case LIST_PRODUCT_GROUP_SELLING_PRICES_BEGIN:
      return {
        ...state,
        productGroupSellingPrices: [],

        loadingProductGroupSellingPrices: true,
        errorProductGroupSellingPrices: null,
      };
    case LIST_PRODUCT_GROUP_SELLING_PRICES_SUCCESS:
      return {
        ...state,
        productGroupSellingPrices: [...data],
        loadingProductGroupSellingPrices: false,
        errorProductGroupSellingPrices: null,
      };
    case LIST_PRODUCT_GROUP_SELLING_PRICES_ERR:
      return {
        ...state,
        productGroupSellingPrices: [],
        loadingProductGroupSellingPrices: false,
        errorProductGroupSellingPrices: err,
      };

    case UPDATE_PRODUCT_SELLING_PRICES_BEGIN:
      return {
        ...state,
        loadingUpdateProductSellingPrices: true,
        errorUpdateProductSellingPrices: null,
      };
    case UPDATE_PRODUCT_SELLING_PRICES_SUCCESS:
      return {
        ...state,
        productGroupSellingPrices: [],
        loadingUpdateProductSellingPrices: false,
        errorUpdateProductSellingPrices: null,
      };
    case UPDATE_PRODUCT_SELLING_PRICES_ERR:
      return {
        ...state,
        loadingUpdateProductSellingPrices: true,
        errorUpdateProductSellingPrices: err,
      };

    case LIST_ALL_PRODUCTS_BEGIN:
      return {
        ...state,
        allProducts: [],
        errorListAllProducts: '',
        loadingListAllProducts: true,
        allProductsTotalCount: 0,
        allProductsTotalPages: 0,
      };
    case LIST_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        allProducts: [...data.results],
        errorListAllProducts: '',
        loadingListAllProducts: false,
        allProductsTotalCount: data.total_count,
        allProductsTotalPages: data.total_pages,
      };
    case LIST_ALL_PRODUCTS_ERR:
      return {
        ...state,
        allProducts: [],
        errorListAllProducts: err,
        loadingListAllProducts: false,
        allProductsTotalCount: 0,
        allProductsTotalPages: 0,
      };
    default:
      return state;
  }
};

const SingleProductReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SINGLE_PRODUCT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SINGLE_PRODUCT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case SINGLE_PRODUCT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { SingleProductReducer, productReducer };
