const actions = {
  // DEVICES ACTIONS
  UPLOAD_DEVICES_BEGIN: 'UPLOAD_DEVICES_BEGIN',
  UPLOAD_DEVICES_SUCCESS: 'UPLOAD_DEVICES_SUCCESS',
  UPLOAD_DEVICES_ERR: 'UPLOAD_DEVICES_ERR',

  LIST_DEVICES_BEGIN: 'LIST_DEVICES_BEGIN',
  LIST_DEVICES_SUCCESS: 'LIST_DEVICES_SUCCESS',
  LIST_DEVICES_ERR: 'LIST_DEVICES_ERR',

  GET_DEVICE_DETAILS_BEGIN: 'GET_DEVICE_DETAILS_BEGIN',
  GET_DEVICE_DETAILS_SUCCESS: 'GET_DEVICE_DETAILS_SUCCESS',
  GET_DEVICE_DETAILS_ERR: 'GET_DEVICE_DETAILS_ERR',

  ASSIGN_DEVICE_BEGIN: 'ASSIGN_DEVICE_BEGIN',
  ASSIGN_DEVICE_SUCCESS: 'ASSIGN_DEVICE_SUCCESS',
  ASSIGN_DEVICE_ERR: 'ASSIGN_DEVICE_ERR',

  DELETE_DEVICE_BEGIN: 'DELETE_DEVICE_BEGIN',
  DELETE_DEVICE_SUCCESS: 'DELETE_DEVICE_SUCCESS',
  DELETE_DEVICE_ERR: 'DELETE_DEVICE_ERR',

  DELETE_DEVICES_BY_ADD_KEY_BEGIN: 'DELETE_DEVICES_BY_ADD_KEY_BEGIN',
  DELETE_DEVICES_BY_ADD_KEY_SUCCESS: 'DELETE_DEVICES_BY_ADD_KEY_SUCCESS',
  DELETE_DEVICES_BY_ADD_KEY_ERR: 'DELETE_DEVICES_BY_ADD_KEY_ERR',

  LIST_DEVICE_CHANGES_BEGIN: 'LIST_DEVICE_CHANGES_BEGIN',
  LIST_DEVICE_CHANGES_SUCCESS: 'LIST_DEVICE_CHANGES_SUCCESS',
  LIST_DEVICE_CHANGES_ERR: 'LIST_DEVICE_CHANGES_ERR',

  LIST_DEVICE_PROVIDERS_BEGIN: 'LIST_DEVICE_PROVIDERS_BEGIN',
  LIST_DEVICE_PROVIDERS_SUCCESS: 'LIST_DEVICE_PROVIDERS_SUCCESS',
  LIST_DEVICE_PROVIDERS_ERR: 'LIST_DEVICE_PROVIDERS_ERR',

  uploadDevicesBegin: () => {
    return {
      type: actions.UPLOAD_DEVICES_BEGIN,
    };
  },

  uploadDevicesSuccess: (data) => {
    return {
      type: actions.UPLOAD_DEVICES_SUCCESS,
      data,
    };
  },

  uploadDevicesErr: (err) => {
    return {
      type: actions.UPLOAD_DEVICES_ERR,
      err,
    };
  },

  listDevicesBegin: () => {
    return {
      type: actions.LIST_DEVICES_BEGIN,
    };
  },

  listDevicesSuccess: (data) => {
    return {
      type: actions.LIST_DEVICES_SUCCESS,
      data,
    };
  },

  listDevicesErr: (err) => {
    return {
      type: actions.LIST_DEVICES_ERR,
      err,
    };
  },

  getDeviceDetailsBegin: () => {
    return {
      type: actions.GET_DEVICE_DETAILS_BEGIN,
    };
  },

  getDeviceDetailsSuccess: (data) => {
    return {
      type: actions.GET_DEVICE_DETAILS_SUCCESS,
      data,
    };
  },

  getDeviceDetailsErr: (err) => {
    return {
      type: actions.GET_DEVICE_DETAILS_ERR,
      err,
    };
  },
  assignDeviceBegin: () => {
    return {
      type: actions.ASSIGN_DEVICE_BEGIN,
    };
  },

  assignDeviceSuccess: (data) => {
    return {
      type: actions.ASSIGN_DEVICE_SUCCESS,
      data,
    };
  },

  assignDeviceErr: (err) => {
    return {
      type: actions.ASSIGN_DEVICE_ERR,
      err,
    };
  },

  deleteDeviceBegin: () => {
    return {
      type: actions.DELETE_DEVICE_BEGIN,
    };
  },

  deleteDeviceSuccess: (data) => {
    return {
      type: actions.DELETE_DEVICE_SUCCESS,
      data,
    };
  },

  deleteDeviceErr: (err) => {
    return {
      type: actions.DELETE_DEVICE_ERR,
      err,
    };
  },

  deleteDevicesByAddKeyBegin: () => {
    return {
      type: actions.DELETE_DEVICES_BY_ADD_KEY_BEGIN,
    };
  },

  deleteDevicesByAddKeySuccess: (data) => {
    return {
      type: actions.DELETE_DEVICES_BY_ADD_KEY_SUCCESS,
      data,
    };
  },

  deleteDevicesByAddKeyErr: (err) => {
    return {
      type: actions.DELETE_DEVICES_BY_ADD_KEY_ERR,
      err,
    };
  },

  listDeviceChangesBegin: () => {
    return {
      type: actions.LIST_DEVICE_CHANGES_BEGIN,
    };
  },

  listDeviceChangesSuccess: (data) => {
    return {
      type: actions.LIST_DEVICE_CHANGES_SUCCESS,
      data,
    };
  },

  listDeviceChangesErr: (err) => {
    return {
      type: actions.LIST_DEVICE_CHANGES_ERR,
      err,
    };
  },

  listDeviceProvidersBegin: () => {
    return {
      type: actions.LIST_DEVICE_PROVIDERS_BEGIN,
    };
  },

  listDeviceProvidersSuccess: (data) => {
    return {
      type: actions.LIST_DEVICE_PROVIDERS_SUCCESS,
      data,
    };
  },

  listDeviceProvidersErr: (err) => {
    return {
      type: actions.LIST_DEVICE_PROVIDERS_ERR,
      err,
    };
  },
};

export default actions;
