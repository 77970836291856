import actions from './actions';

const {
  LIST_REGIONS_BEGIN,
  LIST_REGIONS_SUCCESS,
  LIST_REGIONS_ERR,
  LIST_CITIES_BEGIN,
  LIST_CITIES_SUCCESS,
  LIST_CITIES_ERR,
  LIST_DISTRICTS_BEGIN,
  LIST_DISTRICTS_SUCCESS,
  LIST_DISTRICTS_ERR,
} = actions;

const initState = {
  loadingListRegions: false,
  errorListRegions: '',
  regions: [],
  loadingListCities: false,
  errorListCities: '',
  cities: [],
  loadingListDistricts: false,
  errorListDistricts: '',
  districts: [],
};

const regionsReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LIST_REGIONS_BEGIN:
      return {
        ...state,
        loadingListRegions: true,
        errorListRegions: null,
      };
    case LIST_REGIONS_SUCCESS:
      return {
        ...state,
        regions: [...data],
        loadingListRegions: false,
        errorListRegions: null,
      };
    case LIST_REGIONS_ERR:
      return {
        ...state,
        errorListRegions: err,
        loadingListRegions: false,
      };

    case LIST_CITIES_BEGIN:
      return {
        ...state,
        loadingListCities: true,
        errorListCities: null,
      };
    case LIST_CITIES_SUCCESS:
      return {
        ...state,
        cities: [...data],
        loadingListCities: false,
        errorListCities: null,
      };
    case LIST_CITIES_ERR:
      return {
        ...state,
        errorListCities: err,
        loadingListCities: false,
      };

    case LIST_DISTRICTS_BEGIN:
      return {
        ...state,
        loadingListDistricts: true,
        errorListDistricts: null,
      };
    case LIST_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: [...data],
        loadingListDistricts: false,
        errorListDistricts: null,
      };
    case LIST_DISTRICTS_ERR:
      return {
        ...state,
        errorListDistricts: err,
        loadingListRegions: false,
      };

    default:
      return state;
  }
};

export { regionsReducer };
