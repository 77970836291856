import actions from './actions';

const {
  CREATE_SUPPLIER_BEGIN,
  CREATE_SUPPLIER_SUCCESS,
  CREATE_SUPPLIER_ERR,
  LIST_SUPPLIERS_BEGIN,
  LIST_SUPPLIERS_SUCCESS,
  LIST_SUPPLIERS_ERR,
  UPDATE_SUPPLIER_BEGIN,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_ERR,
} = actions;

const initState = {
  loadingCreateSupplier: false,
  errorCreateSupplier: '',
  suppliers: [],
  totalSuppliers: 0,
  loadingListSuppliers: false,
  errorListSuppliers: '',
  loadingUpdateSupplier: false,
  errorUpdateSupplier: false,
};

const suppliersReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CREATE_SUPPLIER_BEGIN:
      return {
        ...state,
        loadingCreateSupplier: true,
        errorCreateSupplier: '',
      };
    case CREATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        suppliers: [...state.suppliers, data],
        loadingCreateSupplier: false,
        errorCreateSupplier: '',
      };
    case CREATE_SUPPLIER_ERR:
      return {
        ...state,
        errorCreateSupplier: err,
        loadingCreateSupplier: false,
      };

    case LIST_SUPPLIERS_BEGIN:
      return {
        ...state,
        totalSuppliers: 0,
        loadingListSuppliers: true,
        errorListSuppliers: null,
      };
    case LIST_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliers: [...data],
        // totalSuppliers:
        loadingListSuppliers: false,
        errorListSuppliers: null,
      };
    case LIST_SUPPLIERS_ERR:
      return {
        ...state,
        errorListSuppliers: err,
        loadingListSuppliers: false,
      };

    case UPDATE_SUPPLIER_BEGIN:
      return {
        ...state,
        loadingUpdateSupplier: true,
        errorUpdateSupplier: null,
      };
    case UPDATE_SUPPLIER_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedList = state.suppliers.map((item) => (item.app_category_id === data.app_category_id ? data : item));

      return {
        ...state,
        suppliers: [...updatedList],
        loadingUpdateSupplier: false,
        errorUpdateSupplier: null,
      };
    case UPDATE_SUPPLIER_ERR:
      return {
        ...state,
        errorUpdateSupplier: err,
        loadingUpdateSupplier: false,
      };

    default:
      return state;
  }
};

export { suppliersReducer };
