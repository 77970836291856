import axios from 'axios';
import Cookies from 'js-cookie';

import { getItem } from '../../utility/localStorageControl';

const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/`;
const AUTH_CLIENT_ID = process.env.REACT_APP_DASHBOARD_CLIENT_ID;
const AUTH_CLIENT_SECRET = process.env.REACT_APP_DASHBOARD_CLIENT_SECRET;

const authHeader = () => ({
  Authorization: `Bearer ${getItem('access_token')}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'X-App-Client-Id': AUTH_CLIENT_ID,
    'X-App-Client-Secret': AUTH_CLIENT_SECRET,
  },
});

class DataService {
  static get(path = '') {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    });
  }

  static post(path = '', data = {}, isFormData = false, optionalHeader = {}) {
    if (isFormData) {
      // check  the type for image
      data = this.createFormData(data);
    }

    return client({
      method: 'POST',
      url: path,
      data: isFormData ? data : JSON.stringify(data),
      headers: {
        ...authHeader(),
        ...optionalHeader,
        'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
      },
    });
  }

  static patch(path = '', data = {}, isFormData = false) {
    if (isFormData) {
      data = this.createFormData(data);
    }
    return client({
      method: 'PATCH',
      url: path,
      data: isFormData ? data : JSON.stringify(data),
      headers: { ...authHeader(), 'Content-Type': isFormData ? 'multipart/form-data' : 'application/json' },
    });
  }

  static put(path = '', data = {}, isFormData = false) {
    if (isFormData) {
      data = this.createFormData(data);
    }
    return client({
      method: 'PUT',
      url: path,
      data: isFormData ? data : JSON.stringify(data),
      headers: { ...authHeader(), 'Content-Type': isFormData ? 'multipart/form-data' : 'application/json' },
    });
  }

  static delete(path = '', data = {}, isFormData = false) {
    return client({
      method: 'DELETE',
      url: path,
      data: isFormData ? data : JSON.stringify(data),
      headers: { ...authHeader(), 'Content-Type': isFormData ? 'multipart/form-data' : 'application/json' },
    });
  }

  static createFormData(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (value instanceof File && this.isImage(value)) {
        formData.append(key, value);
      } else {
        formData.append(key, value);
      }
    });
    return formData;
  }

  static isImage(file) {
    // Option 1: Check MIME type
    const mimeType = file.type;
    return mimeType.startsWith('image/');

    // Option 2: Check filename extension (less reliable)
    // const extension = file.name.split('.').pop().toLowerCase();
    // return ['jpg', 'jpeg', 'png', 'gif'].includes(extension);
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  const token = getItem('access_token');

  if (token) {
    requestConfig.headers = { ...headers, Authorization: `Bearer ${token}` };
  } else {
    const basicAuthCredentials = btoa(`${AUTH_CLIENT_ID}:${AUTH_CLIENT_SECRET}`);

    requestConfig.headers = { ...headers, Authorization: `Basic ${basicAuthCredentials}` };
  }

  return requestConfig;
});

client.interceptors.response.use(
  (response) => {
    if (response.config.url === '/auth/mobile_login') {
      // reload yhe app to reflect the updates on the UI
      setTimeout(() => window.location.reload(), 500);
    }
    return response;
  },
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */

    const { response } = error;
    const originalRequest = error.config;
    console.log('Original request:: ', originalRequest);
    if (response) {
      //
      if (response.status === 500) {
        // do something here
        return response;
      }
      if (response.status === 401) {
        Cookies.remove('logedIn');
        Cookies.remove('account');
        Cookies.remove('access_token');
        Cookies.remove('loginMobile');
        Cookies.remove('access_token_expiration_date');
        window.location.reload();
        return;
      }
      if (response.status > 300 && response.status < 500) {
        // client error
        return response;
      }
      return response;
    }
    return Promise.reject(error);
  },
);
export { DataService };
