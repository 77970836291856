import actions from './actions';

const {
  ADD_MERCHANT_GROUP_BEGIN,
  ADD_MERCHANT_GROUP_SUCCESS,
  ADD_MERCHANT_GROUP_ERR,
  LIST_MERCHANT_GROUPS_BEGIN,
  LIST_MERCHANT_GROUPS_SUCCESS,
  LIST_MERCHANT_GROUPS_ERR,
  UPDATE_MERCHANT_GROUP_BEGIN,
  UPDATE_MERCHANT_GROUP_SUCCESS,
  UPDATE_MERCHANT_GROUP_ERR,
  ENABLE_MERCHANT_GROUP_BEGIN,
  ENABLE_MERCHANT_GROUP_SUCCESS,
  ENABLE_MERCHANT_GROUP_ERR,
  DELETE_MERCHANT_GROUP_BEGIN,
  DELETE_MERCHANT_GROUP_SUCCESS,
  DELETE_MERCHANT_GROUP_ERR,
  DISABLE_MERCHANT_GROUP_BEGIN,
  DISABLE_MERCHANT_GROUP_SUCCESS,
  DISABLE_MERCHANT_GROUP_ERR,
} = actions;

const initState = {
  loadingAddMerchantGroup: false,
  errorAddMerchantGroup: null,
  merchantGroups: [],
  loadingListMerchantGroups: false,
  errorListMerchantGroups: null,
  loadingUpdateMerchantGroup: false,
  errorUpdateMerchantGroup: false,

  loadingDeleteMerchantGroup: false,
  errorDeleteMerchantGroup: null,

  loadingEnableMerchantGroup: false,
  errorEnableMerchantGroup: null,

  loadingDisableMerchantGroup: false,
  errorDisableMerchantGroup: null,
};

const merchantGroupReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ADD_MERCHANT_GROUP_BEGIN:
      return {
        ...state,
        loadingAddMerchantGroup: true,
        errorAddMerchantGroup: null,
      };
    case ADD_MERCHANT_GROUP_SUCCESS:
      return {
        ...state,
        merchantGroups: [...state.merchantGroups, data],
        loadingAddMerchantGroup: false,
        errorAddMerchantGroup: null,
      };
    case ADD_MERCHANT_GROUP_ERR:
      return {
        ...state,
        errorAddMerchantGroup: err,
        loadingAddMerchantGroup: false,
      };

    case LIST_MERCHANT_GROUPS_BEGIN:
      return {
        ...state,
        loadingListMerchantGroups: true,
        errorListMerchantGroups: null,
      };
    case LIST_MERCHANT_GROUPS_SUCCESS:
      console.log('Merchant Groups list::: ', data);
      return {
        ...state,
        merchantGroups: [...data],
        loadingListMerchantGroups: false,
        errorListMerchantGroups: null,
      };
    case LIST_MERCHANT_GROUPS_ERR:
      return {
        ...state,
        errorListMerchantGroups: err,
        loadingListMerchantGroups: false,
      };

    case UPDATE_MERCHANT_GROUP_BEGIN:
      return {
        ...state,
        loadingUpdateMerchantGroup: true,
        errorUpdateMerchantGroup: null,
      };
    case UPDATE_MERCHANT_GROUP_SUCCESS:
      console.log('Updated Merchant Groups  ::: ', data);

      // eslint-disable-next-line no-case-declarations
      const updatedList = state.merchantGroups.map((item) =>
        item.merchant_group_id === data.merchant_group_id ? data : item,
      );
      console.log('Merchant Groups before update : ', state.merchantGroups);
      console.log('updated Merchant Groups : ', data);
      console.log('Updated merchantGroups list :', updatedList);
      return {
        ...state,
        merchantGroups: [...updatedList],
        loadingUpdateMerchantGroup: false,
        errorUpdateMerchantGroup: null,
      };
    case UPDATE_MERCHANT_GROUP_ERR:
      return {
        ...state,
        errorUpdateMerchantGroup: err,
        loadingUpdateMerchantGroup: false,
      };

    case DELETE_MERCHANT_GROUP_BEGIN:
      return {
        ...state,
        loadingDeleteMerchantGroup: true,
        errorDeleteMerchantGroup: null,
      };
    case DELETE_MERCHANT_GROUP_SUCCESS:
      return {
        ...state,
        loadingDeleteMerchantGroup: false,
        errorDeleteMerchantGroup: null,
      };
    case DELETE_MERCHANT_GROUP_ERR:
      return {
        ...state,
        errorDeleteMerchantGroup: err,
        loadingDeleteMerchantGroup: false,
      };

    case ENABLE_MERCHANT_GROUP_BEGIN:
      return {
        ...state,
        loadingEnableMerchantGroup: true,
        errorEnableMerchantGroup: null,
      };
    case ENABLE_MERCHANT_GROUP_SUCCESS:
      return {
        ...state,
        loadingEnableMerchantGroup: false,
        errorEnableMerchantGroup: null,
      };
    case ENABLE_MERCHANT_GROUP_ERR:
      return {
        ...state,
        errorEnableMerchantGroup: err,
        loadingEnableMerchantGroup: false,
      };

    case DISABLE_MERCHANT_GROUP_BEGIN:
      return {
        ...state,
        loadingDisableMerchantGroup: true,
        errorDisableMerchantGroup: null,
      };
    case DISABLE_MERCHANT_GROUP_SUCCESS:
      return {
        ...state,
        loadingDisableMerchantGroup: false,
        errorDisableMerchantGroup: null,
      };
    case DISABLE_MERCHANT_GROUP_ERR:
      return {
        ...state,
        errorDisableMerchantGroup: err,
        loadingDisableMerchantGroup: false,
      };

    default:
      return state;
  }
};

export { merchantGroupReducer };
